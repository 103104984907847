import { useRef } from "react";
import { useParams } from "react-router-dom";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import videojs from "video.js";

import { ClipExperienceViewModel } from "./ViewModel";
import { VideoPlayer } from "MVVM/VideoPlayer";
import { countClipImpression } from "MVVM/Utilities/Clip";
import { InfoSection } from "./InfoSection";
import { Divider } from "MVVM/Components/Divider";
import { ShareSection } from "./ShareSection";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const ClipExperience = () => {
  const { id } = useParams<{ id: string }>();
  const videoPlayerRef = useRef<videojs.Player | null>(null);
  const { data, error, loading } = ClipExperienceViewModel({
    clipIdentifier: id,
  });

  if (error) return <>Error</>;
  if (loading) return <>Loading...</>;

  const clip = data.getClip;

  return (
    <Box
      style={{
        background: `url(${REACT_APP_S3_CLIP_BUCKET}/${clip.clipImageSource}) 0 0 no-repeat`,
        backgroundSize: "cover",
        height: "100%",
        width: "100%",
      }}
    >
      <ClipContainer style={{ backdropFilter: "blur(14px)" }}>
        <VideoContainer>
          <Box
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            <VideoPlayer
              mp4Source={`${REACT_APP_S3_CLIP_BUCKET}/${clip.clipLink}`}
              refForVideoPlayer={videoPlayerRef}
              onPlayStart={() => countClipImpression(clip.shareId)}
              playAnalyticsProperties={{
                shareId: clip.shareId,
                clipId: clip._id,
                clipOwner: clip.user,
                total_views: clip.views,
                game: clip.game,
              }}
              watchAnalyticsProperties={{
                collection: "Clip Experience",
                collectionType: "Generic",
                item: clip.shareId,
                itemType: "Clip",
                action: "watch",
              }}
              options={{
                autoplay: true,
              }}
            />
          </Box>
        </VideoContainer>
        <Sidebar p={6}>
          <InfoSection clip={clip} />
          <Divider />
          <ShareSection clip={clip} />
          <Divider />
        </Sidebar>
      </ClipContainer>
    </Box>
  );
};

const ClipContainer = styled(Flex)`
  height: 100%;
  width: 100%;
`;

const Sidebar = styled(Box)`
  background: ${({ theme }) => theme.colors.darkerNed};
  width: 441px;
  z-index: 2;
`;

const VideoContainer = styled(Flex)`
  align-items: center;
  flex-grow: 1;
  justify-content: center;

  ::before {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
`;

export { ClipExperience };
