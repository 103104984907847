import { useCallback, useEffect, useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import {
  Mousewheel,
  Pagination,
  Navigation,
  EffectCoverflow,
} from "swiper/modules";
import styled, { css } from "styled-components";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import { FeaturedViewModel } from "./ViewModel";
import { FeaturedContainer } from "../styledComponents";
import { analyticsSlideEvent } from "../../../Components/Overlays/utils";
import { LoadingSkeleton } from "../../../Components/Skeloton";
import { Button } from "../../../Components/Button";
import { IFeaturedItem } from "./@types";
import { FeaturedItem } from "./FeaturedItem";
import { Swiper, SwiperSlide } from "swiper/react";
import background from "MVVM/Assets/Homepage/cs-carousel.png";
import { isMobile } from "react-device-detect";

const INITIAL_SLIDE = 1;

const Featured = () => {
  const { featuredContent, loading } = FeaturedViewModel();
  const [activeIndex, setActiveIndex] = useState<number | null>(INITIAL_SLIDE);
  const [impressionsTracked, setImpressionsTracked] = useState<number[]>([]);

  const handleSlideChange = (realIndex: number | null) => {
    if (!featuredContent.length) return setActiveIndex(INITIAL_SLIDE);
    setTimeout(() => setActiveIndex(realIndex), 800);
  };

  const trackImpression = useCallback(
    (index: number) => {
      const item = featuredContent[index];
      if (!item || impressionsTracked.includes(index)) return;

      setImpressionsTracked([...impressionsTracked, index]);
      analyticsSlideEvent(item.type, item.title);
    },
    [featuredContent, impressionsTracked],
  );

  useEffect(() => {
    trackImpression(0);
  }, [trackImpression]);

  if (loading)
    return (
      <Flex width="100%" style={{ gap: 6 }} flexDirection="column" mt={8}>
        <LoadingSkeleton height="40px" width="20%" />
        <LoadingSkeleton height="230px" width="100%" />
      </Flex>
    );
  if (!featuredContent) return <></>;

  return (
    <FeaturedWrapper style={{ backgroundImage: `url(${background})` }}>
      <FilterWrapper>
        <FeaturedContainer mb={[0, 0, 6]} style={{ position: "relative" }}>
          <CarouselButtonLeft
            to="#"
            classification="secondaryRounded"
            className="slide-prev-featured"
          >
            <FaArrowLeft size={12} />
          </CarouselButtonLeft>
          {isMobile ? (
            <FeaturedSlider
              grabCursor={true}
              loop={false}
              speed={0}
              initialSlide={1}
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              navigation={{
                prevEl: ".slide-prev-featured",
                nextEl: ".slide-next-featured",
              }}
              onRealIndexChange={(slider) => {
                handleSlideChange(slider.realIndex);
                trackImpression(slider.realIndex);
              }}
              className="swiper-transition"
              cssMode={true}
              modules={[Mousewheel, Pagination, Navigation]}
            >
              {[...featuredContent, ...featuredContent].map(
                (featuredItem: IFeaturedItem, index: number) => (
                  <FeaturedSwiperSlide key={index}>
                    <FeaturedItem
                      item={featuredItem}
                      active={activeIndex === index}
                    />
                  </FeaturedSwiperSlide>
                ),
              )}
              <div className="swiper-pagination"></div>
            </FeaturedSlider>
          ) : (
            <FeaturedSlider
              effect="coverflow"
              allowTouchMove={true}
              grabCursor={false}
              centeredSlides={true}
              loop={featuredContent.length > 3 ? true : false}
              initialSlide={1}
              slidesPerView={"auto"}
              mousewheel={true}
              pagination={{
                clickable: true,
              }}
              navigation={{
                prevEl: ".slide-prev-featured",
                nextEl: ".slide-next-featured",
              }}
              onRealIndexChange={(slider) => {
                handleSlideChange(slider.realIndex);
                trackImpression(slider.realIndex);
              }}
              spaceBetween={-200}
              speed={400}
              className="swiper-transition"
              onSlideChange={() => setActiveIndex(null)}
              coverflowEffect={{
                scale: 0.5,
                stretch: 0,
                depth: 200,
                modifier: 0.75,
                rotate: 0,
              }}
              breakpoints={{
                716: {
                  allowTouchMove: false,
                },
              }}
              modules={[EffectCoverflow, Mousewheel, Pagination, Navigation]}
            >
              {[...featuredContent, ...featuredContent].map(
                (featuredItem: IFeaturedItem, index: number) => (
                  <FeaturedSwiperSlide key={index}>
                    <FeaturedItem
                      item={featuredItem}
                      active={activeIndex === index}
                    />
                  </FeaturedSwiperSlide>
                ),
              )}
              <div className="swiper-pagination"></div>
            </FeaturedSlider>
          )}
          <CarouselButtonRight
            to="#"
            classification="secondaryRounded"
            className="slide-next-featured"
          >
            <FaArrowRight size={12} />
          </CarouselButtonRight>
        </FeaturedContainer>
      </FilterWrapper>
    </FeaturedWrapper>
  );
};

const FeaturedWrapper = styled(Box)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
  margin: 0 -60px -16px -60px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    margin: 0 -22px 0 -22px;
  }
`;

const FilterWrapper = styled(Box)`
  backdrop-filter: blur(8px);
  height: 100%;
  padding: 0;
  margin: 0;
  padding: 36px 0 0 0;

  ::before {
    background: linear-gradient(
      0deg,
      rgb(29 42 52) 0%,
      rgb(29 42 52 / 58%) 50%
    );
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    padding: 0;
  }
`;

export const FeaturedSlider = styled(Swiper)`
  height: 280px;
  margin: 0 auto;
  max-width: 2480px;
  padding-top: 30px;
  width: 2480px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    padding-top: 0;
    width: 100%;
    height: auto;
  }

  .swiper-pagination {
    bottom: 4px;
    position: absolute;

    @media (max-width: ${(props) => props.theme.breaks.small}) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    opacity: 0.2;
    background: ${({ theme }) => theme.colors.envy};
    position: relative;
    bottom: 0px;

    @media (max-width: ${(props) => props.theme.breaks.small}) {
      opacity: 1;
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: ${({ theme }) => theme.colors.envy};
  }

  .video-js {
    border-radius: 5px;
  }

  .swiper-transition {
    transition-timing-function: linear;
  }
`;

const FeaturedSwiperSlide = styled(SwiperSlide)`
  height: 234px;
  opacity: 0;
  width: 672px;
  transition: all 0.5s ease-out;

  &.swiper-slide-active {
    opacity: 1;
  }

  &.swiper-slide-prev,
  &.swiper-slide-next {
    opacity: 1;

    @media (min-width: ${(props) => props.theme.breaks.small}) {
      ::before {
        background-color: rgba(0, 0, 0, 0.4);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 3;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 100%;
    height: 100%;
  }
`;

const featuredButton = css`
  align-items: center;
  background: ${({ theme }) => theme.colors.navy};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  top: calc(50% - 16px);
  width: 32px;
  z-index: 2;

  &.swiper-button-disabled {
    opacity: 0;
  }
`;

const CarouselButtonLeft = styled(Button)`
  ${featuredButton}
  left: 60px;

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    left: 4px;
  }
`;

const CarouselButtonRight = styled(Button)`
  ${featuredButton}
  right: 60px;

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    right: 4px;
  }
`;

export { Featured };
