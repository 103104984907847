import styled from "styled-components";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import { Link } from "react-router-dom";

const AppStoreChooser = () => {
  const { REACT_APP_S3_CLIP_BUCKET } = process.env;

  return (
    <Page>
      <StyledHero
        backgroundImage={`${REACT_APP_S3_CLIP_BUCKET}/static/web/mobile/appstorechooser/bg.png`}
      >
        <Content>
          <Headline>Download The Mobile App</Headline>
          <SubHeadline>
            The best way to create and watch gaming content. Now from your
            phone.
          </SubHeadline>
          <Flex style={{ gap: 8 }}>
            <Link
              to="#"
              onClick={() =>
                (window.location.href =
                  "https://apps.apple.com/us/app/allstar-play-share-star/id6476161266")
              }
            >
              <StyledMobileButton
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/mobile/appstorechooser/ios.png`}
                alt="App Store"
              />
            </Link>
            <Link
              to="#"
              onClick={() =>
                (window.location.href =
                  "https://play.google.com/store/apps/details?id=com.androidapp.gg.allstar&hl=en_US&gl=US")
              }
            >
              <StyledMobileButton
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/mobile/appstorechooser/android.png`}
                alt="Google Play Store"
              />
            </Link>
          </Flex>
        </Content>
      </StyledHero>
    </Page>
  );
};

const Content = styled(Flex)`
  flex-direction: column;
  gap: 20px;
  padding: 0 60px;
  width: 40%;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    padding: 0 40px;
    width: 50%;
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    padding: 0 20px;
    width: 60%;
  }
`;

const Headline = styled(Text)`
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.fontSizes[8]};
  font-weight: 900;
  text-shadow: 1px 4px 2px rgba(0, 0, 0, 0.23);

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    font-size: ${({ theme }) => theme.fontSizes[7]};
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    font-size: ${({ theme }) => theme.fontSizes[5]};
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }
`;

const SubHeadline = styled(Text)`
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.fontSizes[5]};
  font-weight: 900;
  opacity: 0.8;
  text-shadow: 1px 4px 2px rgba(0, 0, 0, 0.12);

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    font-size: ${({ theme }) => theme.fontSizes[4]};
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }

  @media (max-width: ${(props) => props.theme.breaks.narrow}) {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`;

const Page = styled(Box)`
  height: calc(100vh - 120px);
  padding: 80px;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    padding: 40px;
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    padding: 20px;
  }
`;

const StyledHero = styled(Flex)`
  align-items: center;
  aspect-ratio: 23/10;
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
`;

const StyledMobileButton = styled(Image)`
  width: 134px;
`;

export default AppStoreChooser;
