const STUDIO_URL = "/studio";
export const URLS = {
  OAUTH_REDIRECT: "connectedaccounts",
  DASHBOARD: "dashboard",
  ACCOUNT_SETTINGS: "accountsettings",
  LOGIN: "/login",
  MATCH_HISTORY: "/match-history",
  LEAGUE_MATCH_HISTORY: "/match-history#league-of-legends",
  CS2_MATCH_HISTORY: "/match-history#counter-strike-2",
  FORTNITE_MATCH_HISTORY: "/match-history#fortnite",
  SIGNUP: "/signup",
  SUPPORT: "/support",
  PROFILE: "/profile",
  CLIPS: "/clips",
  REMIX: "/remix",
  SOCIAL: "/connectedaccounts",
  UPGRADE: "/upgrade",
  COMPETITION: "/competitions",
  MONTAGE: "/montages/create",
  PARTNERS: "/partner/claim",
  OVERWOLF: "/overwolf",
  DEV: "/dev",
  ALLSTAR_GAMERS_CLUB: "/allstar/gamersclub",
  STUDIO: STUDIO_URL,
  STUDIO_CARD: `${STUDIO_URL}/:cardId`,
  NFLUENCE: "/nfluence",
  CURRENT_COMPETITION: "/competitions/clout-grid-competition",
  NOT_FOUND: "/404",
  CONNECTED_ACCOUNTS: "/connectedaccounts",
  SETUP: "/setup",
  AUTH_ROUTES: {
    FACEIT: "/authenticate/faceit",
    DISCORD: "/authenticate/discord",
    RIOT: "/authenticate/riot",
    STEAM: "/authenticate/steam",
    TIKTOK: "/authenticate/tiktok",
    GOOGLE: "/authenticate/google",
    EPIC: "/authenticate/epic",
  },
};

const PARTNER_IDS: any = {
  leetify: "L202211",
  gamersclub: "GC2022",
  scopegg: "SCOPEGG2212",
  esportal: "ESPORTAL23",
};

export const TIER_STRING_MAP: any = {
  500: "Mythic",
  400: "Legendary",
  300: "Rare",
  200: "Premium",
  100: "Standard",
};

export const TIER_USERGROUP_MAP: any = {
  500: "Creator Platinum", // mythic
  400: "Creator Platinum", // legendary
  300: "Pro Plus", // rare
  200: "Pro", // premiumm
  100: "Pro", // standard
};
