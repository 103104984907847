import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";

import { useUser } from "../../../Hooks/useUser";
import { Button } from "../../Button";
import { Avatar } from "../../Avatar";
import { Tooltip } from "../../Tooltip";
import { DropdownNav } from "./DropdownNav";
import { Credits } from "./Credits";
import { NovuComponent } from "../../NovuComponent";

const AuthenticatedSection = () => {
  const { allstarUser } = useUser();

  if (!allstarUser.loggedIn)
    return (
      <Flex style={{ gap: 8 }}>
        <ResponsiveWrapper>
          <Button
            size="small"
            flex={true}
            classification="tertiary"
            to={`/login?next=${window.location.pathname}`}
          >
            Login
          </Button>
        </ResponsiveWrapper>
        <Button size="small" flex={true} to="/register">
          Sign Up
        </Button>
      </Flex>
    );

  return (
    <Flex alignItems="center" style={{ gap: "24px" }}>
      <ResponsiveWrapper>
        <NovuComponent isExperiment={true} />
      </ResponsiveWrapper>
      <ResponsiveWrapper>
        <Credits />
      </ResponsiveWrapper>
      <Tooltip
        isVisible={false}
        type="click"
        tooltipContent={<DropdownNav />}
        position={{ top: "44px", left: "-300px" }}
      >
        <AvatarBorder>
          <StyledAvatar image={allstarUser.user.avatar} size="tiny" />
        </AvatarBorder>
      </Tooltip>
    </Flex>
  );
};

const ResponsiveWrapper = styled(Box)`
  @media (max-width: ${(props) => props.theme.breaks.small}) {
    display: none;
  }
`;

const StyledAvatar = styled(Avatar)``;

const AvatarBorder = styled(Box)`
  border: 2px solid ${({ theme }) => theme.colors.envy} !important;
  border-radius: 20px;
  cursor: pointer;
`;

export { AuthenticatedSection };
