import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink as RoutedLink } from "react-router-dom";
import { Link as RebassLink, Box, Flex } from "rebass/styled-components";
import { FaRegEnvelope, FaDiscord } from "react-icons/fa";
import { RiArrowLeftLine, RiHome6Line } from "react-icons/ri";
import { HiOutlineTrash } from "react-icons/hi";
import { BiCollection } from "react-icons/bi";
import { luminance } from "../../Utilities/Styling";
import theme from "../../../theme/themeThree";
import { HiX } from "react-icons/hi";
import { FloatingContainer, Modal } from "../Wrappers";
import { SiTiktok } from "react-icons/si";
import { Signup } from "../../CallsToAction";
import { EStudioTabView } from "../../@types";
import { useIsDesktopApp } from "../../Hooks/useIsDesktopApp";
import { useTranslation } from "react-i18next";
import { Subheader } from "../Text";
import { TbDeviceMobilePlus } from "react-icons/tb";

//TODO ALL OUR BUTTONS NEED TYPES

export const Button = (props: any) => {
  return (
    <Box
      as={props.to || props.href ? StyledLink : StyledButton}
      sx={{
        ...theme.button,
        ...theme.buttons[props.classification as keyof typeof theme.buttons],
      }}
      variant={`variants.button.${props.size}`}
      {...props}
    >
      {props.children}
    </Box>
  );
};

const StyledRemove = styled(HiOutlineTrash)`
  position: relative;
  top: 7px;
`;

function ClipDelete(props: any) {
  return (
    <Button {...props}>
      <StyledRemove size={18}></StyledRemove>

      {props.children}
    </Button>
  );
}

const StyledDownload = styled(TbDeviceMobilePlus)`
  position: relative;
`;

export function MobileDownload({
  classification,
  onClick,
  size,
  hideOnMobile,
  ...props
}: any) {
  classification = classification ? classification : "secondaryRounded";
  return (
    <Button
      title="Create Mobile Clip"
      display="flex"
      size="social"
      width="100%"
      hideOnMobile={hideOnMobile}
      classification="secondaryRounded"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      onClick={(e: any) => {
        onClick && onClick(e);
      }}
      {...props}
    >
      <StyledDownload size={23}></StyledDownload>
      {props.children}
    </Button>
  );
}

export function CloseButton({
  classification,
  onClick,
  size,
  hideOnMobile,
  ...props
}: any) {
  classification = classification ? classification : "secondary";
  return (
    <StyledCloseButton
      hideOnMobile={hideOnMobile}
      title="Cancel"
      size={size}
      classification={classification}
      flex={true}
      justifyContent="center"
      alignIContent="center"
      onClick={(e: any) => {
        onClick && onClick(e);
      }}
      {...props}
    >
      <StyledClose size={24}></StyledClose>
      {props.children}
    </StyledCloseButton>
  );
}

const StyledClose = styled<any>(HiX)`
  position: relative;
  top: 1px;
  @media (max-width: 425px) {
    top: 4px;
  }
`;

const StyledCloseButton = styled<any>(Button)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    ${(props) => (props.hideOnMobile ? "display:none" : null)};
  }
`;

const flexibleProps: any = {
  width: (props: any) => (props.flex ? false : "100%"),
};

const EmailButton = (props: any) => {
  return (
    <Button {...props}>
      <StyledEmail size={20} mr={props.children ? 3 : null} />
      {props.children}
    </Button>
  );
};

export function Link({ flex, flexShrink, children, ...otherProps }: any) {
  if (otherProps.href === undefined && otherProps.to === undefined) {
    console.warn(
      "Warning: Link `to` is missing, setting `#` instead:",
      children,
    );
    otherProps.to = "#";
  }

  return otherProps.href ? (
    <RebassLink {...otherProps} __css={theme.link}>
      {children}
    </RebassLink>
  ) : (
    <RebassLink __css={theme.link} as={RoutedLink} exact={true} {...otherProps}>
      {children}
    </RebassLink>
  );
}

export function MenuLink(props: any) {
  return (
    <Link variant="menuLink" {...props}>
      {props.children}
    </Link>
  );
}

export function NavLink(props: any) {
  return (
    <Link variant="link.nav" {...props}>
      {props.children}
    </Link>
  );
}

function BackButton({
  classification,
  onClick,
  size,
  hideOnMobile,
  ...props
}: any) {
  classification = classification ? classification : "secondary";
  const isDesktopView = useIsDesktopApp();

  if (isDesktopView.isDesktop) {
    return <></>;
  }
  return (
    <StyledMenuButton
      hideOnMobile={hideOnMobile}
      title="Back to Allstar"
      size={size}
      classification="round"
      flex={true}
      justifyContent="center"
      alignIContent="center"
      onClick={(e: any) => {
        onClick && onClick(e);
      }}
      {...props}
    >
      <StyledBackButton size={24}></StyledBackButton>
      {props.children}
    </StyledMenuButton>
  );
}

export function StudioMainToggle({ view, setView }: any) {
  const { t } = useTranslation("translation", {
    keyPrefix: "studio",
  });
  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setView(event.target.id);
  };

  return (
    <StyledToggleBackground>
      <Toggle
        onClick={handleClick}
        className={view === EStudioTabView.Marketplace ? "active" : undefined}
        id={EStudioTabView.Marketplace}
      >
        <StyledOutlineIcon
          className={view === EStudioTabView.Marketplace ? "active" : undefined}
          size={20}
          id={EStudioTabView.Marketplace}
        />
        {t("marketplace")}
      </Toggle>
      <Toggle
        onClick={handleClick}
        className={view === EStudioTabView.Collections ? "active" : undefined}
        id={EStudioTabView.Collections}
      >
        <StyledGridIcon
          className={view === EStudioTabView.Collections ? "active" : undefined}
          size={20}
          id={EStudioTabView.Collections}
        />
        {t("enabled")}
      </Toggle>
    </StyledToggleBackground>
  );
}

function Toggle({ classification, onClick, size, ...props }: any) {
  return (
    <MarketplaceCollection
      flex={true}
      justifyContent="center"
      alignIContent="center"
      onClick={(e: any) => {
        onClick && onClick(e);
      }}
      {...props}
    >
      {props.children}
    </MarketplaceCollection>
  );
}

// This components state is externally controlled, it does not manage its own state.

const StyledFormLink = styled(Link)`
  ${(props) => props.theme.text.subheader}
`;

export function FormLink(props: any) {
  return (
    <StyledFormLink variant="link.form" {...props}>
      {props.children}
    </StyledFormLink>
  );
}

export function DiscordSocialButton(props: any) {
  return (
    <StyledDiscordButton {...props}>
      <StyledDiscord size={22} mr={props.children ? 3 : null} />
      {props.children}
    </StyledDiscordButton>
  );
}
export function FollowButton({ setFollowModal, ...props }: any) {
  const [state, setState] = useState<any>(null);
  const [firstFollowClick, setFirstFollowClick] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    try {
      return setState(
        props.allstarUser?.followers?.following
          ?.map((x: any) => x?._id)
          .includes(props?.userId)
          ? "Following"
          : "Follow",
      );
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allstarUser?.followers?.following, props.userId]);

  if (!state) return null;

  return (
    <>
      {state === "Follow" ? (
        <StyledFollowButton
          classification={
            props.followClassification ? props.followClassification : "follow"
          }
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!props?.allstarUser?.loggedIn)
              if (setFollowModal) {
                return setFollowModal({
                  displayName: props.displayName,
                  avatar: props.avatar,
                });
              } else return;

            props.followUnfollow({
              variables: { userToFollow: props.userId, follow: true },
            });
            setState("Following");
            window.rudderanalytics.track("User Followed", {});
          }}
          {...props}
        >
          {state}
        </StyledFollowButton>
      ) : (
        <StyledFollowButton
          classification={
            props.followingClassification
              ? props.followingClassification
              : "following"
          }
          className={firstFollowClick ? "hold" : false}
          onMouseEnter={() => {
            setFirstFollowClick(false);
            return setState("Unfollow");
          }}
          onMouseLeave={() => {
            setFirstFollowClick(false);
            return setState("Following");
          }}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!props?.allstarUser?.loggedIn)
              if (setFollowModal) {
                return setFollowModal({
                  displayName: props.displayName,
                  avatar: props.avatar,
                });
              } else return;

            props.followUnfollow({
              variables: { userToFollow: props.userId, follow: false },
            });
            setState("Follow");
            window.rudderanalytics.track("User Unfollowed", {});
          }}
          {...props}
        >
          {state}
        </StyledFollowButton>
      )}
      <Modal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        handleModalClose={undefined}
        disableOutsideClose={false}
      >
        <FloatingContainer>
          <Signup target={props.displayName} avatar={props.avatar} />
        </FloatingContainer>
      </Modal>
    </>
  );
}

export function TiktokSocialButton(props: any) {
  return (
    <StyledTikTokButton {...props}>
      <StyledTiktok size={22} mr={props.children ? 3 : null} />
      {props.children}
    </StyledTikTokButton>
  );
}

export function Note({ children }: any) {
  return <Subheader color="chalk">{children}</Subheader>;
}

const StyledFollowButton = styled(Button)`
  ${(props) =>
    !props.flex
      ? `
  max-width: 104px; 
    @media (max-width: ${props.theme.breaks.medium}) {
      max-width: 104px;
    }
  `
      : null}
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    max-width: 104px;
    min-height: 40px;
    // margin-right: 0px;
    // margin-left: 0px;
  }
`;
const StyledTikTokButton = styled(Button)<any>`
  background: white;
  color: black;
  &:hover {
    background: ${luminance("	#FFFFFF", -0.1)};
  }
`;

const StyledTiktok = styled(SiTiktok)<any>`
  vertical-align: text-top;
  color: black;
  margin-right: ${(props) =>
    props.mr ? `${props.theme.space[props.mr]}px` : null};
`;

const StyledDiscordButton = styled(Button)`
  background: #5865f2;

  &:hover {
    background: ${luminance("#5865F2", -0.1)};
  }
`;

const StyledDiscord = styled(FaDiscord)<any>`
  vertical-align: middle;
  margin-right: ${(props) =>
    props.mr ? `${props.theme.space[props.mr]}px` : null};
`;

const StyledLink = styled(Link)({ ...flexibleProps });

const StyledButton = styled.button({ ...flexibleProps });

Button.defaultProps = {
  size: "default",
  classification: "main",
};

const StyledBackButton = styled(RiArrowLeftLine)`
@media (max-width: ${(props) => props.theme.breaks.medium}) {
  padding: 3px;
`;

const StyledMenuButton = styled(Button)`
  position: relative;
  margin: auto;
  transition: all 0.3s ease;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 999px;
    background: ${(props) => props.theme.colors.ned};
    width: 40px;
    height: 40px;
    transition: all 0.3s ease;
  }
  &:hover {
    border-radius: 999px;
    background: ${(props) => props.theme.colors.ned};
  }
  &:active {
    transform: scale(0.96);
  }
`;

const StyledOutlineIcon = styled(RiHome6Line)<any>`
  color: ${(props) => props.theme.colors.chalk};
  margin-right: 5px;
  min-width: 20px;
  &.active {
    color: #977ada;
  }
  pointer-events: none;
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    // margin-right: 0px;
  }
`;

const StyledGridIcon = styled(BiCollection)<any>`
  color: ${(props) => props.theme.colors.chalk};
  margin-right: 5px;
  min-height: 18px;
  &.active {
    color: #6f8ddc;
  }
  pointer-events: none;
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    // margin-right: 0px;
  }
`;

const StyledToggleBackground = styled(Flex)<any>`
  position: relative;
  flex-direction: row;
  padding: 3px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 46px;
  width: 296px;
  background: ${(props) => props.theme.colors.ned};
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: 240px;
  }
`;

const MarketplaceCollection = styled(Button)`
  position: aboslute;
  display: flex;
  padding: auto 10px;
  align-items: center;
  justify-content: center;
  border-radius: calc(8px);
  height: 40px;
  width: 145px;
  color: ${(props) => props.theme.colors.chalk};
  font-size: 14px;
  font-weight: 500;
  background: ${(props) => props.theme.colors.ned};
  }
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: 120px;
    font-size: 13px;
  }
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    font-size: 12px;
  }
  &:hover {
    background: ${(props) => props.theme.colors.darkerNed};
    color: ${(props) => props.theme.colors.chalk};
  }
  &.active {
    background: ${(props) => props.theme.colors.darkerNavy};
    color: ${(props) => props.theme.colors.ice};
  }
`;

const StyledEmail = styled(FaRegEnvelope)<any>`
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: ${(props) =>
    props.mr ? `${props.theme.space[props.mr]}px` : null};
`;
