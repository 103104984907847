import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";

import Remix from "components/atoms/form/button/remix";
import { ModalType } from "MVVM/@types";
import { ClipReactions } from "MVVM/Components/ClipReactions";
import { AllstarModalState } from "MVVM/State/modals";
import { canRemix } from "MVVM/Utilities/Clip";
import { TClip, ICommentable } from "MVVM/@types";

interface IShareSectionProps {
  clip: TClip & ICommentable;
}

const ShareSection = ({ clip }: IShareSectionProps) => {
  const isFeed = false; // @TODO

  const setAllstarModalState = useSetRecoilState(AllstarModalState);
  const setRemixModalOpen = (isOpen: boolean) => {
    setAllstarModalState({
      data: clip,
      functions: {},
      isOpen: isOpen ? ModalType.Remix : ModalType.None,
    });
  };

  return (
    <Flex style={{ gap: 12 }}>
      {canRemix({ ...clip, isMontage: false }) && (
        <Remix
          classification={undefined}
          classificiation={undefined}
          clip={clip}
          disableOnClick={false}
          isFeed={isFeed}
          onClick={undefined}
          setModalOpen={setRemixModalOpen}
        />
      )}
      <StyledClipReactions clip={clip} />
    </Flex>
  );
};

const StyledClipReactions = styled(ClipReactions)`
  min-height: 40px;
  width: 80px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    height: 36px;
    width: 65px;
  }
`;

export { ShareSection };
