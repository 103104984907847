import { ReactNode, useEffect, useState } from "react";
import { Image, Flex } from "rebass/styled-components";
import styled, { keyframes } from "styled-components";

import { SVG_MAP } from "utils";
import { formatNumber } from "MVVM/Utilities/Clip";

interface IReactionProps {
  comment: {
    reaction: keyof typeof SVG_MAP;
    count: number;
  };
  emptyDisplay?: string;
  onClick: (type: keyof typeof SVG_MAP) => void;
}

const DELAY = 500;

const ReactionEmoji = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, DELAY);
  }, []);

  return visible ? <FloatingEmoji>{children}</FloatingEmoji> : null;
};

const Reaction = ({ comment, emptyDisplay = "-", onClick }: IReactionProps) => {
  const [clickCount, setClickCount] = useState(0);
  const addReaction = () => {
    if (clickCount < 100) {
      onClick(comment.reaction);
      setClickCount(clickCount + 1);
    }
  };

  return (
    <>
      {[...Array(clickCount)].map(() => (
        <ReactionEmoji>
          <ReactionImage
            src={SVG_MAP[comment.reaction as keyof typeof SVG_MAP]}
          ></ReactionImage>
        </ReactionEmoji>
      ))}
      <ReactionWrapper
        alignItems="center"
        data-testid="reaction-wrapper"
        flexDirection="row"
        justifyContent="center"
        onClick={addReaction}
      >
        <Emoji
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <ReactionImage
            src={SVG_MAP[comment.reaction as keyof typeof SVG_MAP]}
          ></ReactionImage>
        </Emoji>
        <ReactionCount variant="reactionCount" data-testid="value">
          {comment.count ? formatNumber(comment.count, 1000) : emptyDisplay}
        </ReactionCount>
      </ReactionWrapper>
    </>
  );
};

const floatAway = keyframes`
  from {
    transform: scale(1.2) translateY(0);
    opacity: 1;
  }

  to {
    transform: scale(2) translateY(-30px) translateX(0) rotate(-25deg);
    opacity: 0;
  }
`;

const ReactionImage = styled(Image)`
  height: 24px;
  width: 40px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    height: 20px;
    width: 20px;
  }
`;

const ReactionWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.navy};
  border-radius: 20px;
  color: ${(props) => props.theme.colors.ice};
  height: 40px;
  padding-bottom: 7px;
  padding-left: 7px;
  padding-right: 8px;
  padding-top: 7px;
  position: relative;
  user-select: none;
  width: 80px;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray850};
  }

  @media (max-width: 1278px) {
    font-size: 0.825rem;
    height: 36px;
    width: 72px;
  }

  @media (max-width: 425px) {
    font-size: 0.825rem;
    height: 36px;
    width: 65px;
  }
`;

const Emoji = styled(Flex)`
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease;
  z-index: 1;

  &:hover {
    transform: scale(1.2);
  }
`;

const FloatingEmoji = styled(Flex)`
  animation-fill-mode: forwards;
  animation: ${floatAway} 0.7s ease-out;
  position: absolute;
  top: 0;
  z-index: 1;
`;

const ReactionCount = styled(Flex)`
  align-items: center;
  cursor: pointer;
  justify-content: center;
  min-height: 26px;
  min-width: 26px;
  padding: 3px 6px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    min-height: 22px;
    min-width: 22px;
    padding: 3px 5px;
  }
`;

export { Reaction };
