//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { MontageSession } from "../../../../State";
import {
  ClipMeta,
  ClipStats,
  ClipTitle,
} from "../Clips/Components/Clip/styledComponents";
import { ModalClip } from "../Clips/Components/ModalClip";
import BoxSvg from "../../../../Assets/box.svg";
import Music from "../../../../Assets/music-note.svg";
import Sparkles from "../../../../Assets/sparkles.svg";
import Outro from "../../../../Assets/CreateMontage/outro.png";
import Intro from "../../../../Assets/CreateMontage/intro.png";
import Broll from "../../../../Assets/CreateMontage/broll.png";
import { TMontageClip } from "../../@types";
import { Flex, Box } from "rebass/styled-components";
import { Body, Subheader, Title1 } from "../../../../Components/Text";
import { LoadingSkeleton } from "../../../../Components/Skeloton";
import { ReactComponent as Montage } from "../../../../Assets/CreateMontage/montage.svg";
import { TagRow } from "../../../../Components/CreatorCard/Components/TagRow";
import { getCardTitle } from "../../../../Utilities/Studio";
import { CreatorCard } from "../../../../Components/CreatorCard";
import { Controls } from "../../../../Components/CreatorCard/Components/Controls";
import { AddButton } from "../FinalTouches/AddButton";
import {
  CardMeta,
  ClipImage,
  ClipLine,
  ClipLineRight,
  ClipWrapper,
  ClipsContainer,
  ErrorContainer,
  ErrorContent,
  ErrorTitle,
  FinalTouch,
  GauranteeDescription,
  GauranteeText,
  GauranteeTitle,
  GuaranteeTitleWrapper,
  Heading,
  LoadoutLineLeft,
  LoadoutLineRight,
  MiddleLineContainer,
  MontageGauranteeContainer,
  ReviewContainer,
  RightContentWrapper,
  SelectionsContainer,
  StartDot,
  StopDot,
  StyledBox,
  StyledHeadline,
  StyledSVG,
  Wrapper,
} from "./styledComponents";

const HEADER_CONTENT = [
  {
    title: "Your Selected Loadout",
    subtitle: "Applies to all clips",
    svg: BoxSvg,
    cardsKey: "loadout",
  },
  {
    title: "Your Finishing Touches",
    subtitle: "Applies to all clips",
    cardsKey: "finishingTouches",
    svg: Sparkles,
  },
  {
    title: "Your Music Choice",
    subtitle: "Applies to entire montage",
    svg: Music,
    cardsKey: "music",
  },
];

export const Review = ({ review }: any) => {
  const [montageSession, setMontageSession] = useRecoilState(MontageSession);

  // const setMontageSession = () => {};
  const [, setFailurePayload] = useState<any | null>(null); //eslint-disable-line
  const [loading, setLoading] = useState<boolean>(false);
  const [errorSubmitted] = useState<boolean>(false); //eslint-disable-line

  const payload = {
    totalKills: montageSession.kills,
    sequenceType: montageSession.sequence.sequenceType,
    clips: montageSession.clips
      .map((x) => `${x._id}`)
      .slice(0, Math.min(montageSession.clips.length, 40)), // here
    creatorCards: montageSession.loadout.cards
      .concat(montageSession.music)
      .map((x) => x._id),
    music: montageSession.music._id,
    amsTiming: montageSession.sequence.buildDoc.amsTiming,
    isValidationRun: !montageSession.validatedSelection,
    // isValidationRun: true,
  };

  useEffect(() => {
    setLoading(true);
    review.montageModel.PostMontage(payload).then((data: any) => {
      if (data.status === "ok") {
        const returnIds = data?.clips.map((x) => x.originalClipId);

        setMontageSession({
          ...montageSession,
          validatedSelection: true,
          clips: montageSession.clips // eslint-disable-next-line
            .map((x) => {
              if (returnIds.includes(x._id)) return x;
            })
            .filter(Boolean),
          returnClips: data?.clips,
          returnIds,
          payload: {
            ...payload,
            clips: returnIds,
            isValidationRun: false,
          },
        });
      } else {
        setMontageSession({
          ...montageSession,
          validatedSelection: false,
          payload: { ...payload, isValidationRun: false },
        });
        setFailurePayload(data.data);
      }
      setLoading(false);
    }); // eslint-disable-next-line
  }, []);

  //eslint-disable-line
  const getContent = (cardsKey) => {
    if (cardsKey === "music") return [montageSession.music];
    if (cardsKey === "loadout") return montageSession.loadout.displayCards;
    if (cardsKey === "finishingTouches")
      return montageSession.finalTouchesSelected;

    return []; // can always map
  };

  if (loading) {
    return (
      <Wrapper flexDirection={"column"} justifyContent="flex-start">
        <Flex justifyContent="center">
          <Flex
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
            pt={4}
          >
            <Montage />
            <Heading>Review your Montage</Heading>
            <Body>
              This is your last chance to review before creating your Montage
            </Body>
          </Flex>
        </Flex>
        <ReviewContainer>
          <ClipsContainer>
            {[1, 1, 1, 1].map((_, index) => {
              return (
                <>
                  {" "}
                  <ClipWrapper justifyContent={"flex-start"}>
                    <StyledBox>
                      <LoadingSkeleton
                        height="300px"
                        style={{ borderRadius: "9px" }}
                        containerClassName="flex-grow-1"
                      />
                    </StyledBox>
                    <Box>
                      {" "}
                      <MontageGauranteeContainer key={index} pt={4}>
                        <GauranteeText key={index}>
                          <LoadingSkeleton
                            height="50px"
                            width="175px"
                            style={{ borderRadius: "9px" }}
                            containerClassName="flex-grow-1"
                          />
                        </GauranteeText>
                      </MontageGauranteeContainer>
                    </Box>
                  </ClipWrapper>
                </>
              );
            })}
          </ClipsContainer>
          <SelectionsContainer>
            {HEADER_CONTENT.map(
              (content: {
                title: string;
                subtitle: string;
                svg: any;
                cardsKey: string;
              }) => {
                const displayContent = getContent(content.cardsKey);
                const isDisplayingCards =
                  content.cardsKey !== "finishingTouches" &&
                  displayContent.length;
                const isDisplayingFinalTouches = !isDisplayingCards;
                return (
                  <ClipWrapper key={content.title}>
                    {" "}
                    <Box>
                      {" "}
                      <MontageGauranteeContainer key={content.title}>
                        <GauranteeText key={content.title}>
                          <LoadingSkeleton
                            height="50px"
                            width="175px"
                            style={{ borderRadius: "9px" }}
                            containerClassName="flex-grow-1"
                          />
                        </GauranteeText>
                      </MontageGauranteeContainer>
                    </Box>
                    {displayContent.length > 0 && (
                      <>
                        {isDisplayingCards && (
                          <>
                            {" "}
                            <StyledBox>
                              <LoadingSkeleton
                                height="300px"
                                style={{ borderRadius: "9px" }}
                                containerClassName="flex-grow-1"
                              />
                            </StyledBox>
                          </>
                        )}
                        {isDisplayingFinalTouches && (
                          <>
                            {" "}
                            <StyledBox>
                              <LoadingSkeleton
                                height="300px"
                                width="200px"
                                style={{ borderRadius: "9px" }}
                                containerClassName="flex-grow-1"
                              />
                            </StyledBox>
                          </>
                        )}
                      </>
                    )}
                  </ClipWrapper>
                );
              },
            )}
          </SelectionsContainer>
        </ReviewContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      flexDirection={"column"}
      justifyContent="flex-start"
      marginBottom={100}
    >
      <Flex justifyContent="center">
        <Flex
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          pt={4}
        >
          <Montage />
          <Heading>Review your Montage</Heading>
          <Body>
            This is your last chance to review before creating your Montage
          </Body>
        </Flex>
      </Flex>
      {montageSession.validatedSelection ? (
        <ReviewContainer>
          <ClipsContainer>
            {montageSession.clips.map((clip: TMontageClip, index: number) => {
              const insertIntro = index === 0;
              const insertOutro = index === montageSession.clips.length - 1;
              const insertBroll =
                insertOutro &&
                !montageSession.sequence.sequenceType.includes("Rapid");
              return (
                <>
                  {insertIntro && (
                    <>
                      <ClipWrapper>
                        <LoadoutLineLeft />
                        <ClipImage src={Intro}></ClipImage>
                        <ClipMeta>
                          <ClipTitle>Personalized Montage Intro</ClipTitle>
                          <ClipStats>
                            Your username set on top of the map of the first
                            clip
                          </ClipStats>
                        </ClipMeta>
                      </ClipWrapper>
                    </>
                  )}
                  {insertBroll && (
                    <>
                      <ClipWrapper>
                        <LoadoutLineLeft />
                        <ClipImage src={Broll} />
                        <ClipMeta>
                          <ClipTitle>Contextual B-Roll</ClipTitle>
                          <ClipStats>Slow pan of a bomb site</ClipStats>
                        </ClipMeta>
                      </ClipWrapper>
                    </>
                  )}
                  <ClipWrapper>
                    <LoadoutLineLeft />{" "}
                    <ModalClip
                      key={clip._id}
                      clip={clip}
                      isSelected={false}
                      hideViewClipButton={true}
                      handleSelect={() => {}}
                    />
                  </ClipWrapper>
                  {insertOutro && (
                    <>
                      <ClipWrapper>
                        <LoadoutLineLeft />
                        <ClipImage src={Outro}></ClipImage>
                        <ClipMeta>
                          <ClipTitle>Allstar Montage Outro</ClipTitle>
                          <ClipStats>
                            Customized with your username and profile URL
                          </ClipStats>
                        </ClipMeta>
                      </ClipWrapper>
                    </>
                  )}
                </>
              );
            })}
          </ClipsContainer>
          <MiddleLineContainer
            paddingTop={["0px", "64px"]}
            width={"20px"}
            paddingBottom={["0px", "253px"]}
          >
            <Flex flexDirection={"column"}>
              <StartDot justifyContent={"flex-start"} />
              <Flex flexDirection={"row"} height={"100%"}>
                <ClipLine />
                <ClipLineRight />
              </Flex>
              <StopDot justifyContent={"flex-end"} />
            </Flex>
          </MiddleLineContainer>
          <SelectionsContainer>
            {HEADER_CONTENT.map(
              (content: {
                title: string;
                subtitle: string;
                svg: any;
                cardsKey: string;
              }) => {
                const displayContent = getContent(content.cardsKey);
                const isDisplayingCards =
                  content.cardsKey !== "finishingTouches" &&
                  displayContent.length;
                const isDisplayingFinalTouches = !isDisplayingCards;
                return (
                  <>
                    <ClipWrapper key={content.title}>
                      {" "}
                      <Box pl={4}>
                        {" "}
                        <MontageGauranteeContainer key={content.title}>
                          <GauranteeText key={content.title}>
                            <GuaranteeTitleWrapper
                              flexDirection={"row"}
                              style={{ position: "relative" }}
                            >
                              <StyledSVG src={content.svg}></StyledSVG>
                              <GauranteeTitle>{content.title}</GauranteeTitle>
                            </GuaranteeTitleWrapper>

                            <GauranteeDescription>
                              {content.subtitle}
                            </GauranteeDescription>
                          </GauranteeText>
                        </MontageGauranteeContainer>
                      </Box>
                      <RightContentWrapper>
                        {" "}
                        <LoadoutLineRight />
                        {displayContent.length === 0 && (
                          <Box textAlign={"left"} pl={3}>
                            <Subheader>No Finishing Touches applied</Subheader>
                          </Box>
                        )}
                        {displayContent.length > 0 && (
                          <>
                            {displayContent.map((display, index) => {
                              let card;
                              if (isDisplayingCards) {
                                //for readability sake
                                card = display;
                              }
                              if (content.cardsKey === "loadout" && index === 0)
                                return (
                                  <>
                                    {" "}
                                    <StyledBox key={card._id}>
                                      <CreatorCard
                                        card={card}
                                        isModal={false}
                                        setModal={() => {}}
                                        handleCardClick={() => {}}
                                        handleModalClose={() => {}}
                                      />
                                      <Controls
                                        handleCardClick={() => {}}
                                        card={card}
                                        hideToolTip={true}
                                        handleToggle={(e: any) => {}}
                                        onQuery={() => {}}
                                        isActive={true}
                                        outsideControl={true}
                                      />
                                    </StyledBox>
                                  </>
                                );
                              if (isDisplayingFinalTouches) {
                                return (
                                  <>
                                    {" "}
                                    <FinalTouch p={2}>
                                      {" "}
                                      <Box pr={3}>
                                        {" "}
                                        <AddButton
                                          handleToggle={() => {}}
                                          isActive={true}
                                          parentHovered={false}
                                        />
                                      </Box>
                                      <Body>{display}</Body>
                                    </FinalTouch>
                                  </>
                                );
                              }
                              return (
                                <CardMeta>
                                  {" "}
                                  <Flex
                                    flexDirection="column"
                                    justifyContent="flex-start"
                                    mb={3}
                                    alignItems={"start"}
                                  >
                                    <StyledHeadline ml={3}>
                                      {getCardTitle(card)}
                                    </StyledHeadline>
                                  </Flex>
                                  <Flex flexDirection="row">
                                    <TagRow card={card} onQuery={() => {}} />
                                  </Flex>
                                </CardMeta>
                              );
                            })}
                          </>
                        )}
                      </RightContentWrapper>
                    </ClipWrapper>
                  </>
                );
              },
            )}
          </SelectionsContainer>
        </ReviewContainer>
      ) : (
        <ErrorContainer height="100%">
          <ErrorContent p={4} mt={4}>
            {!errorSubmitted && (
              <>
                <ErrorTitle> uh-oh...</ErrorTitle>
                <Body my={4}>Looks like something went wrong on our end</Body>
                <Body mt={1}>
                  Please contact support at support@allstar.gg or in our
                  Discord!
                </Body>
              </>
            )}
            {errorSubmitted && (
              <>
                <Title1> Thanks! </Title1>{" "}
                <Body mt={4}>
                  A member of Allstar support will reach out to you ASAP!
                </Body>
              </>
            )}
          </ErrorContent>
        </ErrorContainer>
      )}
    </Wrapper>
  );
};
