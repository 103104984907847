import { useEffect, useRef } from "react";
import styled from "styled-components";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import videojs from "video.js";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isMobile } from "react-device-detect";

import { IFeaturedItem } from "../@types";
import { ModalType } from "MVVM/@types";
import { ClipFullModalState } from "MVVM/State/modals";
import { analyticsClickEvent } from "MVVM/Components/Overlays/utils";
import { VideoPlayer } from "MVVM/VideoPlayer";
import {
  OverlayBadge,
  OverlayButton,
  OverlayTag,
} from "../../../../Components/Overlays";
import { FeaturedUser } from "../../FeaturedUser";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const FeaturedItem = ({
  item,
  active,
}: {
  item: IFeaturedItem;
  active: boolean;
}) => {
  const videoPlayerRef = useRef<videojs.Player | null>(null);
  const history = useHistory();
  const mp4src = `${REACT_APP_S3_CLIP_BUCKET}/${item.mp4Src}`;
  const posterImage = `${REACT_APP_S3_CLIP_BUCKET}/${item.thumbnail}`;
  const [, setClipFullModalState] = useRecoilState(ClipFullModalState);
  const isModal = true;

  useEffect(() => {
    if (!videoPlayerRef?.current) return;

    active
      ? videoPlayerRef.current.play()?.catch(() => {})
      : videoPlayerRef.current.pause();
  }, [active]);

  return (
    <FeaturedItemWrapper className="featured-item">
      <VideoPlayerRounder>
        <VideoContainer
          className="video-container"
          onClick={() => {
            analyticsClickEvent(item.type, item.title);

            if (!item.link.length) return;

            if (isModal && item.shareId)
              return setClipFullModalState({
                isOpen: ModalType.ClipFull,
                shareId: item.shareId,
              });

            item.external ? window.open(item.link) : history.push(item.link);
          }}
        >
          <Image
            src={posterImage}
            width="100%"
            style={{
              position: "absolute",
              zIndex: 1,
              borderTopLeftRadius: 9,
              borderBottomLeftRadius: 9,
              transition: "opacity 0.5s ease-in-out",
              opacity: `${active ? 0 : 1}`,
            }}
          />
          <Box
            style={{
              transition: "opacity 0.5s ease-in-out",
              opacity: `${active ? 1 : 0}`,
              zIndex: 2,
            }}
          >
            <OverlayBadge type={item.type} />
            <VideoPlayer
              refForVideoPlayer={videoPlayerRef}
              mp4Source={mp4src}
              hlsSource={item.hlsSrc}
              playAnalyticsProperties={{
                shareId: item.shareId,
                source: "Homepage Featured",
              }}
              watchAnalyticsProperties={{
                action: "watch",
                collection: "Homepage featured",
                collectionType: "Generic",
                item: item.shareId,
                itemType: "Clip",
              }}
              options={{
                aspectRatio: "16:9",
                autoplay: active,
                controls: false,
                fill: true,
                fluid: true,
                loop: true,
                muted: true,
                playsinline: true,
                poster: posterImage,
                responsive: true,
                userActions: {
                  click: false,
                },
              }}
            />
          </Box>
        </VideoContainer>
      </VideoPlayerRounder>
      <VideoSection>
        <Flex flexDirection="column">
          <Flex
            flexDirection={["row-reverse", "row-reverse", "column"]}
            justifyContent="space-between"
            mr={[4, 4, 0]}
          >
            <OverlayTag
              tag={item.tag}
              style={{
                alignSelf: "flex-start",
                borderRadius: "20px",
                display: "inline-block",
                fontSize: "0.7em",
                margin: "0 0 12px 0",
              }}
            />
            <SectionTitle variant="text.title2" mb={3}>
              {item.type === "cotd"
                ? "Clip of the Day"
                : item.type === "motw"
                ? "Montage of the Week"
                : item.title}
            </SectionTitle>
          </Flex>
          <MobileHide>
            {["motw", "cotd"].includes(item.type) && (
              <FeaturedUser
                avatar={item.avatar}
                createdDate={item.createdDate}
                userId={item.userId}
                username={item.username}
                views={item.views}
              />
            )}
          </MobileHide>
        </Flex>
        <OverlayButton
          buttonCopy={
            item.type === "cotd"
              ? "Go To Clip of the Day!"
              : item.type === "motw"
              ? "Check out the MotW!"
              : item.buttonCopy
          }
          external={item.external}
          link={item.link}
          name={item.title}
          type={item.type}
          buttonAction={item?.buttonAction}
          style={{
            borderRadius: "12px",
            fontWeight: 700,
            lineHeight: "1em",
            marginTop: "auto",
            position: "static",
          }}
        />
      </VideoSection>
    </FeaturedItemWrapper>
  );
};

const FeaturedItemWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.darkerNed};
  border-radius: 12px;
  height: 234px;
  width: 672px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    flex-direction: column;
    margin: 0 auto;
    width: 100vw;
    height: auto;
  }
`;

const VideoPlayerRounder = styled(Box)`
  position: relative;

  .video-js,
  .vjs-poster,
  .vjs-tech,
  .vjs-modal-dialog-content,
  .vjs-error-display {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;

    @media (max-width: ${(props) => props.theme.breaks.small}) {
      border-radius: 0;
    }
  }

  .video-js .vjs-control-bar {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const VideoSection = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.darkerNed};
  flex-direction: column;
  justify-content: space-around;
  padding: 24px;
  width: 240px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 18px;
    width: 100%;
    height: 100px;
  }
`;

const MobileHide = styled(Box)`
  @media (max-width: ${(props) => props.theme.breaks.small}) {
    display: none;
  }
`;

const SectionTitle = styled(Text)`
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    font-size: ${({ theme }) => theme.fontSizes[3]};
    margin-top: -2px;
    margin-bottom: 20px;
  }
`;

const VideoContainer = styled(Box)`
  height: 234px;
  width: 416px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    height: auto;
    width: 100%;
  }
`;

export { FeaturedItem };
