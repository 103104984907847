import { useQuery } from "@apollo/client";
import { SUGGESTED_CREATORS } from "MVVM/GraphQL/suggestedCreators";

const SuggestedFeedViewModel = () => {
  const { data, error, loading } = useQuery(SUGGESTED_CREATORS, {
    fetchPolicy: "network-only",
    variables: { count: 12 },
  });

  return {
    data,
    error,
    loading,
  };
};

export { SuggestedFeedViewModel };
