import { useEffect, useRef } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { useHistory, useParams } from "react-router-dom";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import videojs from "video.js";
import { SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination, Navigation } from "swiper/modules";

import {
  MoreButton,
  SubHeading,
  StyledSlider,
  StyledFlex,
} from "./styledComponents";
import { ClipFullViewModel } from "./ViewModel";
import { VideoPlayer } from "../../VideoPlayer";
import { Owner } from "./Owner";
import { OwnerHeadline } from "./OwnerHeadline";
import { ClipPreview } from "./ClipPreview";
import { CardPreview } from "./CardPreview";
import { IClipPreview, ICardPreview } from "./@types";
import { ClipFullLoading } from "./ClipFullLoading";
import { ShareOptions } from "./ShareOptions";
import { ClipRemix } from "../../Components/Clip/Components/ClipRemix";
import { ErrorBoundary } from "react-error-boundary";
import { countClipImpression, getClipTitle } from "../../Utilities/Clip";
import EditableText from "../../Components/Text/Editable";
import { useUser } from "../../Hooks/useUser";
import { useGateValue } from "@statsig/react-bindings";
import { ClipExperience } from "../ClipExperience";

interface IClipFull {
  closeModal?: () => void;
  modalView?: boolean;
  shareId?: string;
}

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const ClipFull = ({ shareId, modalView, closeModal }: IClipFull) => {
  const clipExperienceEnabled = useGateValue("new_clip_page");
  if (clipExperienceEnabled) return <ClipExperience />;

  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const { allstarUser } = useUser();
  const {
    data,
    loading,
    userData,
    userDataLoading,
    mutateFollowers,
    editTitle,
  } = ClipFullViewModel({
    clipId: shareId || id,
  });
  const videoPlayerRef = useRef<videojs.Player | null>(null);
  const isOwnClip = data?.getClip?.user?._id === allstarUser?.user?._id;

  useEffect(() => {
    let unlisten;
    if (closeModal) unlisten = history.listen(closeModal);
    return unlisten;
  }, [closeModal, history]);

  if (loading || userDataLoading)
    return <ClipFullLoading modalView={modalView} />;

  const { getClip: clip } = data;
  const { username } = userData.userProfile.profile;

  const ClipError = ({ error }: { error: Error }) => {
    console.log(`Clip feed Error: ${error}`);
    return <SubHeading>Could not load clip!</SubHeading>;
  };

  const onPlayStart = () => countClipImpression(clip.shareId);

  return (
    <StyledFlex
      modalView={modalView}
      flexDirection="column"
      alignItems="center"
    >
      <Box style={{ width: "100%", maxWidth: "1000px" }}>
        {isOwnClip ? (
          <Box mb={12} style={{ position: "relative" }}>
            <EditableText
              variant="headingMedium"
              defaultValue={getClipTitle(clip) || ""}
              onSave={(value: string) => editTitle(clip._id, value, false)}
            />
          </Box>
        ) : (
          <Text variant="text.headingMedium" mb={12}>
            {getClipTitle(clip)}
          </Text>
        )}

        <Owner
          username={username}
          clip={clip}
          style={{ marginBottom: "24px" }}
          closeModal={closeModal}
        />
        <ErrorBoundary FallbackComponent={ClipError}>
          <VideoPlayer
            hlsSource={clip.cf?.hls}
            mp4Source={`${REACT_APP_S3_CLIP_BUCKET}/${clip.clipLink}`}
            refForVideoPlayer={videoPlayerRef}
            onPlayStart={onPlayStart}
            playAnalyticsProperties={{
              shareId: clip.shareId,
              clipId: clip._id,
              clipOwner: clip.user,
              total_views: clip.views,
              game: clip.game,
            }}
            watchAnalyticsProperties={{
              collection: "Clip Modal",
              collectionType: "Generic",
              item: clip.shareId,
              itemType: "Clip",
              action: "watch",
            }}
            options={{
              autoplay: true,
            }}
          />
        </ErrorBoundary>
        <ClipRemix
          views={clip.views}
          followUnfollow={mutateFollowers}
          clip={clip}
          height="100%"
          setFollowModal={true}
          onClick={undefined}
          classification={undefined}
          alignment={undefined}
        />
        <ShareOptions clip={clip} />
        <OwnerHeadline
          clip={clip}
          stats={userData.userProfile.stats}
          style={{ margin: "30px 0" }}
          closeModal={closeModal}
          mutateFollowers={mutateFollowers}
          username={username}
        />
        {!!userData.clips?.data?.length && (
          <>
            <SubHeading pl={52}>More from {username}</SubHeading>
            <Flex alignItems="center" mb={60} style={{ gap: "20px" }}>
              <MoreButton
                to="#"
                width="100%"
                display="flex"
                classification="secondaryRounded"
                alignItems="center"
                className="slide-prev-clip"
              >
                <FaArrowLeft size={12} />
              </MoreButton>
              <StyledSlider
                centeredSlides={false}
                spaceBetween={30}
                slidesPerView={3}
                mousewheel={true}
                navigation={{
                  prevEl: ".slide-prev-clip",
                  nextEl: ".slide-next-clip",
                }}
                breakpoints={{
                  1280: {
                    slidesPerView: 3,
                  },
                  905: {
                    slidesPerView: 2,
                  },
                  0: {
                    slidesPerView: 1,
                  },
                }}
                modules={[Mousewheel, Pagination, Navigation]}
              >
                {userData.clips.data.map((clip: IClipPreview) => (
                  <SwiperSlide>
                    <ClipPreview clip={clip} closeModal={closeModal} />
                  </SwiperSlide>
                ))}
              </StyledSlider>
              <MoreButton
                to="#"
                width="100%"
                display="flex"
                classification="secondaryRounded"
                alignItems="center"
                className="slide-next-clip"
              >
                <FaArrowRight size={12} />
              </MoreButton>
            </Flex>
          </>
        )}

        {!!clip.personalizations?.cards?.length && (
          <>
            <SubHeading pl={52}>Studio Cards used</SubHeading>
            <Flex alignItems="center" mb={60} style={{ gap: "20px" }}>
              <MoreButton
                to="#"
                width="100%"
                display="flex"
                classification="secondaryRounded"
                alignItems="center"
                className="slide-prev-card"
              >
                <FaArrowLeft size={12} />
              </MoreButton>
              <StyledSlider
                centeredSlides={false}
                spaceBetween={30}
                slidesPerView={3}
                mousewheel={true}
                navigation={{
                  prevEl: ".slide-prev-card",
                  nextEl: ".slide-next-card",
                }}
                breakpoints={{
                  1280: {
                    slidesPerView: 3,
                  },
                  905: {
                    slidesPerView: 2,
                  },
                  0: {
                    slidesPerView: 1,
                  },
                }}
                modules={[Mousewheel, Pagination, Navigation]}
              >
                {clip.personalizations.cards.map((card: ICardPreview) => (
                  <SwiperSlide>
                    <CardPreview card={card} closeModal={closeModal} />
                  </SwiperSlide>
                ))}
              </StyledSlider>
              <MoreButton
                to="#"
                width="100%"
                display="flex"
                classification="secondaryRounded"
                alignItems="center"
                className="slide-next-card"
              >
                <FaArrowRight size={12} />
              </MoreButton>
            </Flex>
          </>
        )}
      </Box>
    </StyledFlex>
  );
};

export default ClipFull;
