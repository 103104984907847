import styled, { createGlobalStyle } from "styled-components";
import { Flex, Box } from "rebass/styled-components";
import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";

import { UserStateProvider } from "./userStore";
import Background from "./components/background";
import { GetApp } from "./MVVM/Components/GetApp";
import { PrefillLedgerBalance } from "./MVVM/Providers";
import { ModalManager } from "./MVVM/Components/ModalManager";
import { SiteNotice } from "./MVVM/Components/SiteNotice";
import { AppRoutes } from "./routes";
import { Navigation } from "./navigation";
import { Footer } from "./footer";
import { SiteHeader } from "./MVVM/Components/SiteHeader";
import { usePortal } from "MVVM/Hooks/usePortal";
import { Wrapped } from "MVVM/Views/Wrapped";

const Main = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const game = urlParams.get("game");
  const location = useLocation();
  const {
    portalOpen: wrappedPortalOpen,
    setPortalOpen: setWrappedPortalOpen,
    Portal: WrappedPortal,
  } = usePortal(<Wrapped period="2024" />, "root");

  const wrappedEnabled = useMemo(() => {
    //eslint-disable-next-line
    const wrappedRegExp = /^\/wrapped\/2024\/[^\/\s]+$/g;

    return wrappedRegExp.test(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (wrappedEnabled) setWrappedPortalOpen(true);
  }, [setWrappedPortalOpen, wrappedEnabled]);

  if (game) localStorage.setItem("game", game);
  if (window.document.referrer)
    localStorage.setItem("initialReferrer", window.document.referrer);

  useEffect(() => {
    document.getElementById("main").scrollTo(0, 0);
  }, [location]);

  return (
    <UserStateProvider>
      {wrappedPortalOpen && WrappedPortal}
      <GlobalStyle />
      <LayeredBackground />
      {createPortal(<GetApp />, document.body)}
      <SiteNotice />
      <PrefillLedgerBalance />
      <LayeredContent>
        <SiteHeader />
        <ColumnWrapper>
          <LeftColumn>
            <Navigation />
          </LeftColumn>
          <MainColumn>
            <SiteContent id="sitecontent">
              <MainContent id="main">
                <AppRoutes />
                <Footer />
              </MainContent>
            </SiteContent>
          </MainColumn>
        </ColumnWrapper>
      </LayeredContent>
      <ModalManager />
    </UserStateProvider>
  );
};

const ColumnWrapper = styled(Flex)`
  position: relative;
  height: 100%;
`;

const GlobalStyle = createGlobalStyle`
  html {
    @media (max-width: ${(props) => props.theme.breaks.narrow}) {
      font-size: 14px;
    }
  }
`;

const MainContent = styled(Box)`
  overflow-y: auto;
  width: 100%;
  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #192832;
    border-left: 1px solid #20323f;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #253a49;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const LeftColumn = styled(Flex)`
  flex-shrink: 0;
  height: 100%;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    position: absolute;
    z-index: 4;
  }
`;

const MainColumn = styled(Flex)`
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const SiteContent = styled(Flex)`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const LayeredBackground = styled(Background)`
  z-index: 1;
`;

const LayeredContent = styled(Flex)`
  flex-direction: column;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-direction: column;
  }
`;

export { Main };
