import React, { forwardRef } from "react";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components";
import { Validation } from "../Form/Validation";
import { Subheader } from "../Text";
import { HiCheck } from "react-icons/hi";
import { GoDash } from "react-icons/go";

export const CheckboxWithLabel = ({ wrap, flex, ...props }: any) => {
  const Wrapper = wrap ? (
    <Label alignContent="center" flex={flex} htmlFor={props.checkbox?.id} />
  ) : (
    <Container />
  );

  return (
    <>
      {props.error ? (
        <Validation mb={3}>
          {props.error.message ? props.error.message : "Required"}
        </Validation>
      ) : (
        <></>
      )}
      <Wrapper.type
        variant={props.applySpace ? "form" : null}
        {...Wrapper.props}
      >
        <Checkbox
          {...props.checkbox}
          onClick={props.onClick}
          error={props.error}
        />
        <Subheader sx={{ ...props.labelStyles }}>{props.children}</Subheader>
      </Wrapper.type>
    </>
  );
};

export const Checkbox = forwardRef((props: any, ref: any) => {
  return (
    <>
      <Input
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        type="checkbox"
        name={props.name}
        className={props.partial ? "partial" : false}
        id={props.id}
        ref={ref}
        onChange={props.onChange}
        onClick={(e: any) => {
          if (props.onClick) {
            props.onClick(e);
          }
        }}
        disabled={props.disabled ? props.disabled : false}
      />
      <Indicator
        error={props.error}
        borderColor={props.borderColor}
        style={{ curser: "pointer" }}
      >
        {props.partial ? <Partial /> : <Checkmark />}
      </Indicator>
    </>
  );
});
const Input = styled.input<any>`
  opacity: 0;
  position: absolute;
  width: ${(props) => props.theme.checkbox.width}px;
  height: ${(props) => props.theme.checkbox.height}px;
  top: 0;
  left: 0;
  z-index: 2;

  &:disabled {
    cursor: no-drop;
  }
`;

const Indicator = styled.span<any>`
  background: transparent;
  border: 2px solid
    ${(props: any) =>
      props.error
        ? props.theme.colors.error
        : props.borderColor || props.theme.colors.ice};
  border-radius: 6px;
  color: transparent;
  display: inline-flex;
  height: ${(props) => props.theme.checkbox.height}px;
  position: absolute;
  align-items: center;
  align-content: center;
  justify-content: center;
  top: 3px;
  left: 0;
  transition: background-color 0.1s ease, border-color 0.1s ease;
  vertical-align: middle;
  width: ${(props) => props.theme.checkbox.width}px;
  z-index: 1;
  border-color: ${(props: any) =>
    props.error
      ? props.theme.colors.error
      : props.borderColor || props.theme.colors.chalkAlpha40};
  input:focus + &,
  input:hover + & {
    border-color: ${(props: any) =>
      props.error ? props.theme.colors.error : props.theme.colors.envy};
  }

  input.partial + &,
  input:checked + & {
    background-color: ${(props) => props.theme.colors.envy};
    border-color: ${(props) => props.theme.colors.envy};
    color: ${(props) => props.theme.colors.ice};
  }
`;

const Checkmark = styled(HiCheck)`
  height: 16px;
  width: 16px;
  position: relative;
`;

const Partial = styled(GoDash)`
  height: 16px;
  width: 16px;
  position: relative;
`;

const containerStyle = css`
  padding-left: 30px;
  padding-top: 4px;
  position: relative;
  vertical-align: middle;
  curser: pointer;
  justify-content: center;
  align-items: center;
  a {
    font-size: inherit;
    white-space: nowrap;
  }
`;

const Container = styled(Box)`
  ${containerStyle}
`;
const Label = styled.label<any>`
  cursor: pointer;
  flex: ${(props) => props.flex};
  ${containerStyle};
`;
