import { YearEndWrap2024 } from "./2024";

interface IYearEndWrapProps {
  period?: string;
  username?: string;
}

const Wrapped = ({ period, username }: IYearEndWrapProps) => {
  switch (period) {
    case "2024":
      return <YearEndWrap2024 username={username} />;
    default:
      return <></>;
  }
};

export { Wrapped };
