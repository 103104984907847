import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useStatsigClient } from "@statsig/react-bindings";

import { HOMEPAGE_CONTENT_FEED } from "../../GraphQL/homepage";
import { useUser } from "../../Hooks/useUser";

const limit = 12;

const HomepageViewModel = () => {
  const { allstarUser } = useUser();
  const { getExperiment } = useStatsigClient();
  const startDate = useMemo(
    () => String(new Date(new Date().setMonth(new Date().getMonth() - 12))),
    [],
  );

  let personalized = false;
  if (allstarUser.loggedIn) {
    personalized = getExperiment("personalized_for_you").get(
      "personalized",
      false,
    );
  }

  const { data: contentFeeds, loading: contentFeedsLoading } = useQuery(
    HOMEPAGE_CONTENT_FEED(personalized),
    {
      variables: {
        limit,
        search: "popular",
        startDate,
      },
      fetchPolicy: "cache-and-network",
    },
  );

  return {
    allstarUser,
    contentFeeds,
    contentFeedsLoading,
  };
};

export { HomepageViewModel };
