import { gql } from "@apollo/client";

export const WRAPPED = gql`
  query wrappedSummary($username: String!) {
    wrappedSummary(username: $username) {
      clipReactions
      clip {
        id
        shareId
        thumbnailUrl(style: STANDARD)
        title
        url(orientation: LANDSCAPE)
        views
      }
      framesSaved
      gbStored
      hoursSaved
      totalViews
      user {
        avatarUrl
        username
      }
      watchTime
    }
  }
`;

export const CLIPS_RELOADED = gql`
  query clipsReloaded($limit: Int) {
    clipsReloaded(limit: $limit) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        thumbnailStandardUrl: thumbnailUrl(style: STANDARD)
        thumbnailOverlayUrl: thumbnailUrl(style: OVERLAY)
        previewUrl
        title
        createdAt
        shareId
        views
        score
        user {
          _id
          avatarUrl
          username
        }
      }
      edges {
        cursor
      }
    }
  }
`;
