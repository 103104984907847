import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Box, Flex, Image } from "rebass/styled-components";
import { FaInstagram, FaDiscord, FaFacebookF, FaYoutube } from "react-icons/fa";
import styled from "styled-components";
import { FaXTwitter } from "react-icons/fa6";
import { FiChevronDown } from "react-icons/fi";

import { Link as FooterLink } from "../atoms/footer/link";
import { Title as FooterTitle } from "../atoms/footer/title";
import { Link } from "../atoms/link";
import { URLS } from "../../MVVM/Utilities/Maps";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;
const HelpLinks = [
  {
    to: "/howitworks",
    title: "How Allstar works",
  },
  {
    to: "/feature/allstarstudio",
    title: "What is Allstar Studio",
  },
  {
    to: URLS.UPGRADE,
    title: "Subscriptions",
  },
  {
    to: "/faq",
    title: "FAQ",
  },
  {
    to: "/support",
    title: "Support Form",
  },
];

const Em = styled.span`
  color: ${(props) => props.theme.colors.envy};
  font-weight: 500;
`;

const CompanyLinks = [
  {
    to: "/about",
    title: "About Allstar",
  },
  {
    to: "/brand",
    title: "Brand",
  },
  {
    href: "/careers",
    title: (
      <>
        Careers - <Em>We're hiring!</Em>
      </>
    ),
  },
  {
    href: "https://allstar.canny.io/feature-requests",
    target: "_blank",
    rel: "noopener noreferrer",
    title: "Feature Requests",
  },
  {
    href: "https://allstar.canny.io/feature-requests?category=new-games&sort=top",
    title: "Game Requests",
  },
];

const LegalLinks = [
  {
    to: "/legal/privacy-policy",
    title: "Privacy Policy",
  },
  {
    to: "/legal/terms-of-use",
    title: "Terms of Use",
  },
  {
    to: "/legal/do-not-sell-my-personal-information",
    title: "Do not sell my personal information",
  },
  {
    to: "/legal/vulnerability-disclosure-policy",
    title: "Vulnerability Disclosure Policy",
  },
  {
    to: "/legal/community-trust-and-safety-guidelines",
    title: "Community Trust and Safety Guidelines",
  },
];

export const FollowLinks = [
  {
    href: "https://twitter.com/playsharestar",
    icon: <FaXTwitter size={30} color="white" />,
  },
  {
    href: "https://instagram.com/playsharestar",
    icon: <FaInstagram size={30} color="white" />,
  },
  {
    href: "https://www.youtube.com/playsharestar",
    icon: <FaYoutube size={30} color="white" />,
  },
  {
    href: "https://discord.gg/playsharestar",
    icon: <FaDiscord size={30} color="white" />,
  },
  {
    href: "https://www.facebook.com/PlayShareStar",
    icon: <FaFacebookF size={30} color="white" />,
  },
];

const FooterLinkRow = ({ index, link }) => {
  return (
    <Box mb={[1, 1, 4]} key={index}>
      <FooterLink {...link}>{link.title}</FooterLink>
    </Box>
  );
};

const FooterTitleRow = ({ open, setOpen, children }) => {
  return (
    <ResponsiveFooterTitle onClick={() => setOpen(!open)}>
      {children}
      <ResponsiveChevron size={22} open={open} />
    </ResponsiveFooterTitle>
  );
};

const Footer = ({ history }) => {
  const [helpOpen, setHelpOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [legalOpen, setLegalOpen] = useState(false);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setHelpOpen(false);
      setCompanyOpen(false);
      setLegalOpen(false);
    });
    return unlisten;
  }, [history]);

  return (
    <FooterWrapper>
      <FooterContainer>
        <Column>
          <FooterTitleRow setOpen={setHelpOpen} open={helpOpen}>
            Help
          </FooterTitleRow>
          <FooterLinks open={helpOpen}>
            {HelpLinks.map((link, index) => {
              return <FooterLinkRow index={index} key={index} link={link} />;
            })}
          </FooterLinks>
        </Column>
        <Column>
          <FooterTitleRow setOpen={setCompanyOpen} open={companyOpen}>
            Company
          </FooterTitleRow>
          <FooterLinks open={companyOpen}>
            {CompanyLinks.map((link, index) => {
              return <FooterLinkRow index={index} key={index} link={link} />;
            })}
          </FooterLinks>
        </Column>
        <Column>
          <FooterTitleRow setOpen={setLegalOpen} open={legalOpen}>
            Legal
          </FooterTitleRow>
          <FooterLinks open={legalOpen}>
            {LegalLinks.map((link, index) => {
              return <FooterLinkRow index={index} key={index} link={link} />;
            })}
          </FooterLinks>
        </Column>
        <Column>
          <ResponsiveFooterTitle>Social</ResponsiveFooterTitle>
          <SocialLinks>
            {FollowLinks.map((link, index) => {
              return (
                <FooterLink
                  key={index}
                  index={index}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link.href}
                >
                  {link.icon}
                </FooterLink>
              );
            })}
          </SocialLinks>
          <MobileLinks>
            <Link
              href="https://apps.apple.com/us/app/allstar-play-share-star/id6476161266"
              target="_blank"
            >
              <StyledMobileButton
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/mobile/appstorechooser/ios.png`}
                alt="App Store"
              />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=com.androidapp.gg.allstar&hl=en_US&gl=US"
              target="_blank"
            >
              <StyledMobileButton
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/mobile/appstorechooser/android.png`}
                alt="Google Play Store"
              />
            </Link>
            <Link
              href="https://discord.com/application-directory/580572502288498700"
              target="_blank"
            >
              <StyledMobileButton
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/mobile/appstorechooser/bot.png`}
                alt="Discord Bot"
              />
            </Link>
          </MobileLinks>
        </Column>
      </FooterContainer>
    </FooterWrapper>
  );
};

const FooterWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.darkerNed};
  justify-content: center;
  width: 100%;
`;

const FooterContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  margin-bottom: 60px;
  max-width: 1600px;
  padding: 60px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    cursor: pointer;
    flex-direction: column;
    gap: 0;
    padding: 0;
    width: 100%;
  }
`;

const Column = styled(Box)`
  flex: 1;
  min-width: 200px;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    border-bottom: 1px solid ${(props) => props.theme.colors.navy};
    cursor: pointer;
    margin-bottom: ${(props) => props.theme.space[2]}px;
    margin-top: ${(props) => props.theme.space[4]}px;
  }
`;

const StyledMobileButton = styled(Image)`
  width: 160px;
`;

const ResponsiveFooterTitle = styled(FooterTitle)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-grow: 1;
    padding: 0 20px;
  }
`;

const FooterLinks = styled(Box)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: ${(props) => (props.open ? "block" : "none")};
    padding-bottom: ${(props) => props.theme.space[4]}px;

    a {
      display: block;
      padding-top: ${(props) => props.theme.space[3]}px;
      padding-bottom: ${(props) => props.theme.space[3]}px;
      padding-left: 20px;

      :hover {
        background: ${({ theme }) => theme.colors.ned};
      }
    }
  }
`;

const MobileLinks = styled(Flex)`
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 60px;

  a {
    flex-shrink: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
    padding: 0 20px 40px 20px;
  }
`;

const SocialLinks = styled(Flex)`
  gap: 40px;
  margin-bottom: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 0 20px;
  }

  > svg {
    flex-grow: 1;
  }
`;

const ResponsiveChevron = styled(FiChevronDown)`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    align-self: flex-end;
    display: block;
    transform: ${(props) => (props.open ? "rotate(180deg)" : "none")};
  }
`;

export default withRouter(Footer);
