import { useQuery } from "@apollo/client";

import { GET_CLIP } from "MVVM/GraphQL/clip";

interface ClipExperienceViewModelProps {
  clipIdentifier: string;
}

const ClipExperienceViewModel = ({
  clipIdentifier,
}: ClipExperienceViewModelProps) => {
  const { data, loading, error } = useQuery(GET_CLIP, {
    variables: {
      clipIdentifier,
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    data,
    error,
    loading,
  };
};

export { ClipExperienceViewModel };
