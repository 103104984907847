import styled from "styled-components";
import SVG from "react-inlinesvg";
import { Box, Flex, Image } from "rebass/styled-components";

import {
  Headline,
  Subheader,
  Title1,
  Title2,
} from "../../../../Components/Text";
import theme from "../../../../../theme/themeThree";

export const MiddleLineContainer = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;

export const ErrorContainer = styled(Flex)`
  flex-direction: column;
  width: 500px;

  border-radius: 9px;
`;

export const ErrorContent = styled(Flex)`
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => {
    return props.theme.colors.midnightAlpha50;
  }};

  border-radius: 9px;
`;

export const ErrorTitle = styled(Title1)`
  color: ${(props) => {
    return props.theme.colors.error;
  }};
  font-weight: 600;
`;
export const ClipImage = styled(Image)<any>`
  display: flex;
  width: 100%;
  border-radius: 8px;
`;

export const FinalTouch = styled(Flex)`
  width: 100%;
  justify-content: flex-start;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledBox = styled(Box)`
  pointer-events: none;
  color: ${({ theme }) => theme.colors.ice};
  border-radius: 14px;
  border: ${(props) => (props.selected ? "2px dotted #4dbd94" : "")};
`;

export const StartDot = styled(Flex)`
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(113, 171, 238, 1) 0%,
    rgba(141, 96, 216, 1) 100%
  );
  border-radius: 999px;
  left: -14px;
  top: 10px;
  width: 36px;
  height: 15px;
  border: 3px solid
    ${(props) => {
      return props.theme.colors.ned;
    }};
`;

export const StopDot = styled(Flex)`
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(113, 171, 238, 1) 0%,
    rgba(141, 96, 216, 1) 100%
  );
  border-radius: 999px;
  left: -14px;
  bottom: -30px;
  width: 36px;
  height: 15px;
  border: 3px solid
    ${(props) => {
      return props.theme.colors.ned;
    }};
`;

export const ClipLine = styled(Flex)`
  background: linear-gradient(
    180deg,
    rgba(113, 171, 238, 1) 0%,
    rgba(113, 171, 238, 1) 100%
  );
  border-radius: 14px;
  width: 10px;
  height: 102%;
  border: 1px solid
    ${(props) => {
      return props.theme.colors.ned;
    }};

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;

export const ClipLineRight = styled(Flex)`
  background: linear-gradient(
    180deg,
    rgba(160, 96, 216, 1) 0%,
    rgba(160, 96, 216, 1) 100%
  );
  border-radius: 14px;
  width: 10px;
  height: 102%;
  border: 1px solid
    ${(props) => {
      return props.theme.colors.ned;
    }};
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;

export const LoadoutLineLeft = styled(Flex)`
  background: rgba(113, 171, 238, 1);
  width: 55px;
  height: 4px;
  position: relative;
  left: 420px;
  top: 10%;
  opacity: 0.3;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;

export const LoadoutLineRight = styled(Flex)`
  background: rgba(160, 96, 216, 1);
  width: 51px;
  height: 4px;
  position: relative;
  left: -61px;
  top: 5%;
  opacity: 0.3;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;

export const StyledHeadline = styled(Headline)`
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
`;
export const CardMeta = styled(Flex)`
  position: relative;
  pointer-events: none;
  flex-direction: column;
  justify-content: flex-start;
  background: ${theme.colors.darkerNed};
  border-radius: 10px 10px 10px 10px;
  padding: 15px;
  width: 100%;
  &:hover {
    cursor: pointer;
    background: ${theme.colors.midnightAlpha50};
  }
`;

export const RightContentWrapper = styled(Flex)`
  width: 100%;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  background: ${({ theme }) => theme.colors.midnightAlpha40};
  border-radius: 8px;
  z-index: 10;
`;

export const GuaranteeTitleWrapper = styled(Flex)`
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
`;

export const GauranteeText = styled(Flex)`
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    justify-content: center;
    text-align: center;
  }
`;

export const GauranteeTitle = styled(Headline)`
  text-align: left;
  font-weight: 500;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.ice};

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    flex-direction: column;
  }
`;

export const GauranteeDescription = styled(Subheader)`
  text-align: left;
  font-weight: 500;
  line-height: 18px;
  align-items: flex-start;
  width: 100%;
  color: ${({ theme }) => theme.colors.chalk};
`;
export const MontageGauranteeContainer = styled(Flex)`
  flex-direction: row;
  margin-top: 25px;
  max-width: 250px;
  padding-bottom: 15px;
  align-items: flex-start;
`;

export const StyledSVG = styled(SVG)`
  vertical-align: middle;
  position: absolute;
  left: -25px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  flex-shrink: 0;
`;

export const ClipWrapper = styled(Box)`
  pointer-events: none;
  position: relative;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
`;
export const Wrapper = styled(Flex)`
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  align-items: center;
  margin-bottom: 200px;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    margin-bottom: 235px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ReviewContainer = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  width: 950px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-direction: column;
    align-items: center;
    gap: 25px;
    width: 450px;
  }
`;

export const ClipsContainer = styled(Flex)`
  padding-top: 50px;
  justify-content: flex-start;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    justify-content: center;
    align-items: center;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const SelectionsContainer = styled(Flex)`
  padding-top: 50px;
  justify-content: flex-start;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Heading = styled(Title2)`
  color: ${({ theme }) => theme.colors.ice};
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
`;
