import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { AllstarUser, xsollaModalOpen } from "../../State";
import { IAuthenticatedUserQuery, User } from "../../Model/User";
import { useMutateFollowers } from "../useMutateFollowers";
import { useUser } from "../useUser";
import { useIsDesktopApp } from "../useIsDesktopApp";
import { FTUE_STATE } from "../../GraphQL/ftue";
import { FTUEGameState, FTUEState, TFlowState } from "../../State/ftue";
import { useIsMobileApp } from "../useIsMobileApp";
import { GET_ME } from "../../GraphQL/user";

const BASE_URL: string = `${process.env.REACT_APP_API_URL}`;

const get = (key: string) => {
  const data = localStorage.getItem(key);
  if (!data) return null;
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

export const UserStatePreFill = ({
  force,
}: {
  force: boolean; //
}) => {
  const location = useLocation();
  const history = useHistory();
  const isDesktopApp = useIsDesktopApp();
  const isMobileApp = useIsMobileApp();
  const [allstarUser, setAllstarUser] = useRecoilState(AllstarUser); //eslint-disable-line
  const { logout } = useUser();
  const { requestFollowers } = useMutateFollowers();
  const [ftueRecoilState, setFtueRecoilState] = useRecoilState(FTUEState);
  const [ftueGameState, setFtueRecoilGameState] = useRecoilState(FTUEGameState);
  const [paystation, setPaystationOpen] = useRecoilState(xsollaModalOpen);
  const [ftueState] = useLazyQuery(FTUE_STATE, {
    fetchPolicy: "network-only",
  });
  const [getMe] = useLazyQuery<IAuthenticatedUserQuery>(GET_ME, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    runPreFill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [force]);

  const runPreFill = async () => {
    if (isDesktopApp.isDesktop || isMobileApp.isMobileApp) {
      if (isDesktopApp.token) {
        localStorage.setItem("token", isDesktopApp.token);
      }
      if (isMobileApp.token) {
        localStorage.setItem("token", isMobileApp.token);
      }
    }

    const token = get("token");

    if (!token) return;

    // set the cookie for the iframe, as localStorage is domain specific
    if (document) {
      document.cookie = `token=${token}; SameSite=None; Secure; path=/`;
    }

    const userModel = User.getInstance();
    const decoded = await userModel.DecodeToken();
    if (!decoded) return;

    const isEscalated = !typeof decoded.escalated === undefined;
    const authenticatedUserQuery = await getMe();
    const userProfile = authenticatedUserQuery?.data?.me;

    if (!userProfile) {
      logout("INVALID TOKEN");
      return;
    }

    let createdDate = new Date(userProfile.user.createdDate);
    if (createdDate.toString() === "Invalid Date") {
      const numberDate = Number(userProfile.user.createdDate);
      createdDate = new Date(numberDate);
    }

    const stateData = {
      _id: userProfile.user._id,
      admin: userProfile.user.admin,
      avatar: userProfile.user.avatar,
      credits: userProfile.user.credits,
      discord: userProfile.user.discord,
      discordSignUp: userProfile.user.discordSignUp,
      faceit: userProfile.user.faceit,
      google: userProfile.user.google,
      hasClips: false,
      hasSharecodes: userProfile.metadata.hasSharecodes,
      hasUsername: userProfile.profile?.username ? true : false,
      noEmailSet: userProfile.metadata.noEmailSet,
      profile: userProfile.profile,
      rank: userProfile.user.rank,
      riot: userProfile.user.riot,
      steam: userProfile.user.steam,
      epic: userProfile.user.epic,
      tiktok: userProfile.user.tiktok,
      userGroup: userProfile.user.userGroup,
      userScopes: userProfile.user.scope,
      stats: userProfile.stats,
      createdDate: createdDate,
    };
    window.rudderanalytics.identify(decoded?._id, {
      email: decoded.sub,
      userGroup: stateData?.userGroup?.type,
    });

    setAllstarUser({
      escalated: isEscalated,
      loggedIn: true,
      isAdmin: decoded.admin,
      token: token,
      user: stateData,
      decoded: decoded,
      BASE_URL,
      myAvatar: stateData.avatar,
    });

    await requestFollowers({
      variables: { profiles: true, clips: false },
    });
    if (paystation?.isModalOpen) {
      return setTimeout(() => {
        setPaystationOpen({ ...paystation, isModalOpen: false });
      }, 3000);
    }
    if (
      !location.pathname.includes("/iframe") &&
      !location.pathname.includes("/authenticate")
    ) {
      try {
        const { data: ftue } = (await ftueState()) as {
          data: {
            ftue: {
              hasCompleted: boolean;
              step: TFlowState;
              stepHistory: TFlowState[];
              gameSelection: string[];
            };
          };
        };

        if (ftue.ftue.hasCompleted === null)
          return setFtueRecoilState({
            ...ftueRecoilState,

            stateLoaded: true,
          });
        if (ftue.ftue.hasCompleted === false) {
          setFtueRecoilState({
            ...ftueRecoilState,
            step: ftue.ftue.step ?? "profile",
            stepHistory: ftue.ftue.stepHistory ?? [],
            stateLoaded: true,
          });

          setFtueRecoilGameState((prevState) => {
            return {
              ...prevState,
              gameSelection: prevState.gameSelection.map((game) => {
                return {
                  ...game,
                  selected: ftue?.ftue?.gameSelection?.includes(game.id),
                };
              }),
            };
          });
          if (
            !location.pathname.includes("setup") &&
            !location.pathname.includes("oauth")
          ) {
            return history.push("/setup");
          }
        } else {
          return setFtueRecoilState({
            ...ftueRecoilState,
            stateLoaded: true,
            ftueComplete: true,
          });
        }
      } catch (error) {
        console.log("Error from ftueState query: ", error);
      }
    }

    if (!location.pathname.includes("iframe")) {
      const claimIntent = localStorage.getItem("claimIntent");
      if (allstarUser?.user?.steam?.active && claimIntent) {
        localStorage.removeItem("claimIntent");
        localStorage.removeItem("claimable");
        return history.push("/setup");
      }
    }
  };

  return <React.Fragment></React.Fragment>;
};
