import { useEffect, useState } from "react";

import { SVG_MAP } from "utils";
import { TComment } from "MVVM/@types";

const useComments = (clipComments: TComment[]) => {
  const [comments, setComments] = useState(clipComments || []);
  const findTopReaction = (comments: TComment[]) =>
    comments.reduce(
      (max, comment) => (comment.count > max.count ? comment : max),
      comments[0],
    );
  const [topReaction, setTopReaction] = useState(findTopReaction(comments));

  const optimisticReactionUpdate = (type: keyof typeof SVG_MAP) => {
    const reactions = comments.map((comment) => {
      if (comment.reaction === type) {
        const copy = { ...comment };
        copy.count++;
        return copy;
      }

      return comment;
    });

    return setComments(reactions);
  };

  useEffect(() => {
    setTopReaction(findTopReaction(comments));
  }, [comments]);

  return { comments, optimisticReactionUpdate, topReaction };
};

export { useComments };
