import { Box } from "rebass/styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination, Navigation } from "swiper/modules";
import { useUser } from "MVVM/Hooks/useUser";

import { SectionTitle } from "../styledComponents";
import { SuggestedItem } from "../SuggestedItem";
import { SuggestedFeedViewModel } from "./ViewModel";
import { ISuggestedUser } from "../@types";
import { Loading } from "../Loading";

const SuggestedFeed = () => {
  const { allstarUser } = useUser();
  const { data, error, loading } = SuggestedFeedViewModel();

  if (loading) return <Loading />;
  if (!data || error) return <></>;

  const { suggestedCreators } = data;

  return (
    <Box>
      <SectionTitle mb={4}>Suggested</SectionTitle>
      <Swiper
        centeredSlides={false}
        spaceBetween={32}
        slidesPerView="auto"
        mousewheel={true}
        modules={[Mousewheel, Pagination, Navigation]}
      >
        {suggestedCreators.map((suggested: ISuggestedUser, index: number) => (
          <SwiperSlide key={index} style={{ width: "auto" }}>
            <SuggestedItem suggested={suggested} allstarUser={allstarUser} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export { SuggestedFeed };
