const formatNumber = (num: string | number) =>
  num
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .replaceAll("0", "O");

const favorableWatchTimeDisplay = (num: number) => {
  if (num > 3600) return { value: Math.floor(num / 60 / 60), units: "Hours" };
  if (num > 60) return { value: Math.floor(num / 60), units: "Minutes" };

  return { value: Math.floor(num), units: "Seconds" };
};

export { favorableWatchTimeDisplay, formatNumber };
