import React from "react";
import Legal from "./index";
import { Body } from "../../components/text";
import styled from "styled-components";

const VulnerabilityDisclosurePolicy = () => {
  return (
    <Legal>
      <StyledBody>
        <p>Last updated on May 1st, 2024</p>
        <div style={{ paddingBottom: 100 }}>
          <h2 data-testid="legal/vulnerability-disclosure-policy">
            Vulnerability Disclosure Policy
          </h2>

          <h3>Introduction</h3>
          <p>
            At Allstar Gaming, Inc., safeguarding our digital environment and
            the data of our users is a top priority. We value the critical role
            that security researchers and our user community play in maintaining
            the security of our systems. This document outlines our policy and
            procedures for reporting vulnerabilities or security concerns
            discovered within our systems.
          </p>

          <h3>Reporting Vulnerabilities</h3>
          <p>
            Should you discover a security vulnerability on our platform or
            within any of our services, we urge you to report it to us without
            delay by sending an email to secops@allstar.gg. Please provide
            comprehensive details about the nature of the vulnerability, steps
            to reproduce it, and any other information that might assist us in
            evaluating and rectifying the issue.
          </p>

          <h3>Our Commitment</h3>
          <p>
            Each report received is taken seriously and examined thoroughly by
            our security team.
          </p>
          <p>
            Due to the volume of reports we receive and the detailed
            investigation each requires, we do not respond directly to security
            reports. Rest assured, your report will be reviewed diligently.
          </p>
          <p>
            Our team is committed to verifying and addressing any confirmed
            security issues promptly, following our established security
            protocols.
          </p>

          <h3>Policy on Bug Bounties</h3>
          <p>
            Please be aware that Allstar Gaming, Inc. does not operate a bug
            bounty program and does not offer compensation for vulnerability
            reports. We kindly request that reports are submitted without any
            expectation of receiving a bounty or payment.
          </p>

          <h3>Ethical Considerations and Legal Boundaries</h3>
          <p>
            Attempts to identify vulnerabilities through network scanning,
            automated probing, or exploitation of systems are not authorized or
            condoned.
          </p>
          <p>
            Any actions undertaken should not adversely affect Allstar Gaming,
            Inc., our customers, or our online services. This includes avoiding
            actions that could lead to privacy breaches, data destruction, or
            any interruption or degradation of our services.
          </p>
          <p>
            It is strictly prohibited to access or attempt to access data or
            information that does not belong to you.
          </p>

          <h3>Conclusion</h3>
          <p>
            We are grateful to the security community for their efforts in
            responsibly reporting vulnerabilities. Your contributions are
            invaluable to the security and integrity of our services. However,
            adherence to the guidelines above is essential for ensuring a
            constructive and legal approach to vulnerability disclosure.
          </p>
          <p>
            Allstar Gaming, Inc. reserves the right to pursue legal action
            against individuals who engage in unauthorized activities against
            our systems or services.
          </p>
          <p>Thank you for respecting our policy and for your cooperation.</p>
        </div>
      </StyledBody>
    </Legal>
  );
};

const StyledBody = styled(Body)`
  line-height: 1.444;
  font-size: 1.125rem;
`;
export default VulnerabilityDisclosurePolicy;
