import React, { Key } from "react";

import { Box, Flex } from "rebass";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { CreatorCard } from "../../../../Components/CreatorCard";
import { MontageSession } from "../../../../State";
import { CountIndicator } from "../CountIndicator";
import { Grid } from "../../../../Components/Grid";

import { TLoadout, ILoadoutData, GAMES } from "../../@types";
import { LoadingSkeleton } from "../../../../Components/Skeloton";
import { Controls } from "../../../../Components/CreatorCard/Components/Controls";
import { DEFAULT_LOADOUT } from "../../Defaults";
import { ProLoadout } from "../../../../Components/Tooltip/Components/ProLoadout";

interface ILoadoutProps extends ILoadoutData {
  setModal: (modal: { body: string; isOpen: boolean }) => void;
}

export const LoadoutSelector = ({
  loadoutsData,
  loadoutsLoading,
  setModal,
}: ILoadoutProps) => {
  const [montageSession, setMontageSession] = useRecoilState(MontageSession);
  const isFree = montageSession.isFreeUser;
  const onSelect = (loadout: TLoadout) => {
    if (montageSession.loadout.name !== loadout.name) {
      setMontageSession({ ...montageSession, loadout: loadout });
    }
  };

  if (loadoutsLoading) return <Loading />;

  return (
    <>
      <CountIndicator
        heading={"Loadouts"}
        count={montageSession.loadout.name ? 1 : 0}
        max={1}
        canProceed={!!montageSession.loadout.name}
      />
      {/* <ScrollableWrapper> */}{" "}
      <Grid paddingBottom={150}>
        <>
          {loadoutsData?.loadouts?.map((loadout: TLoadout) => {
            if (
              loadout.name === "Random" &&
              isFree &&
              montageSession.game === GAMES.CSGO
            ) {
              onSelect(loadout);
            }
            if (
              loadout.name === "No Filter" &&
              isFree &&
              montageSession.game === GAMES.CounterStrike2
            ) {
              onSelect(loadout);
            }
            const FakeCard = {
              // we are treating a loadout as a creator card, and a loadout doesnt have these fields so just doing this for now
              _id: loadout.name,
              facet: { series: [""], type: [""], theme: [""], mechanic: [""] },
              scopeUse: [],
              scopeVis: [],
              ui: {
                hoverEffect: loadout.hoverEffect,
                image: loadout.image,
                hoverEffectSrc: loadout.hoverEffectSrc,
                title: loadout.name,
              },
            };
            const isActive = montageSession?.loadout?.name === loadout.name;
            return (
              <StyledBox key={loadout.name as Key}>
                <CreatorCard
                  card={FakeCard}
                  handleCardClick={() => {}}
                  handleModalClose={() => {}}
                  setModal={() => {}}
                  isModal={false}
                />
                <Flex flexDirection={"column"}>
                  <Controls
                    handleCardClick={() => {}}
                    card={FakeCard}
                    handleToggle={() => {
                      if (isFree) {
                        setModal({
                          body: `Pick your Montage Loadout, get Pro Only Creator Cards, a Profile Badge, Exclusive Content and much more!`,
                          isOpen: true,
                        });
                        return false;
                      }

                      if (isActive) {
                        onSelect(DEFAULT_LOADOUT);
                      } else {
                        onSelect(loadout);
                      }

                      return true;
                    }}
                    onQuery={() => {}}
                    outsideControl={true}
                    isActive={isActive}
                    loadout={loadout}
                    tooltipContent={<ProLoadout />}
                    hideToolTip={!montageSession.isFreeUser}
                  />
                </Flex>
              </StyledBox>
            );
          })}
        </>
      </Grid>
      {/* </ScrollableWrapper> */}
    </>
  );
};

const Loading = () => {
  return (
    <>
      <Flex
        justifyContent={"center"}
        width="100%"
        pb={10}
        flexDirection="column"
      >
        <Box pb={1} alignSelf="flex-start">
          <LoadingSkeleton
            width={"125px"}
            height={"28px"}
            style={{ borderRadius: "8px" }}
            containerClassName={"align-self-start"}
          />
        </Box>
        <Grid>
          {[1, 1, 1, 1, 1, 1, 1, 1].map((_, index: number) => {
            return (
              <LoadingSkeleton
                key={index}
                height={"250px"}
                style={{ borderRadius: "14px" }}
                containerClassName="flex-grow-1"
              />
            );
          })}
        </Grid>
      </Flex>
    </>
  );
};

const StyledBox = styled(Box)`
  color: ${({ theme }) => theme.colors.ice};
  background: ${({ theme }) => theme.colors.midnightAlpha40};
  border-radius: 14px;
  border: ${(props) => (props.selected ? "2px dotted #4dbd94" : "")};
  &:hover {
    cursor: pointer;
  }
`;
