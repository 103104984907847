import React, { useEffect } from "react";

import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { Body, Title3 } from "../../../../Components/Text";
import { Button } from "../../../../Components/Button";
import { useTranslation } from "react-i18next";
import { ImageWithLoading } from "../../../../Components/ImageWithLoading";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";
import { useHistory } from "react-router-dom";

const StyledBox = styled(Box)<any>`
  width: 100%;
`;

const StyledTitle3 = styled(Title3)<any>`
  font-weight: 600;
`;

type SideRailProps = {
  name?: string;
  title?: string;
  subtitle?: string;
  image?: string;
  link?: string;
  buttonCopy?: string;
  buttonAction?: ModalType;
};

export const SideRailAd = ({
  title,
  subtitle,
  image,
  link,
  buttonCopy,
  name,
  buttonAction,
}: SideRailProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "crossSitePromo",
  });
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);
  const history = useHistory();
  useEffect(() => {
    window.rudderanalytics.track("Promotion - Impression", {
      type: "sideRail",
      promotionName: name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledBox variant="sidebar.new">
      <Flex flexDirection="column">
        <ImageWithLoading
          aspectRatio={9 / 16}
          imageSource={`${process.env.REACT_APP_S3_STATIC}/${image}`}
        />

        <Flex flexDirection="column">
          <StyledTitle3 mb={2} mt={4}>
            {t(`${title}`, {
              defaultValue: title || "",
            })}
          </StyledTitle3>
          <Body color="chalk">
            {t(`${subtitle}`, {
              defaultValue: subtitle || "",
            })}
          </Body>
        </Flex>
        <Button
          mt={[4]}
          size="small"
          classification="secondary"
          flex={true}
          onClick={() => {
            window.rudderanalytics.track("Promotion - Button Click", {
              type: "sideRail",
            });

            if (buttonAction) {
              return setAllstarModalState({
                isOpen: buttonAction,
                functions: {},
                data: {},
              });
            }
            if (link) history.push(link);
          }}
        >
          {t(`${buttonCopy}`, {
            defaultValue: buttonCopy || "",
          })}
        </Button>
      </Flex>
    </StyledBox>
  );
};
