import styled from "styled-components";
import { Flex } from "rebass/styled-components";

import { Featured } from "./Featured";
import { ContentFeed } from "./ContentFeed";
import { SuggestedFeed } from "./SuggestedFeed";
import { HomepageWrapper } from "./styledComponents";
import { HomepageViewModel } from "./ViewModel";
import { ESupportedGames } from "../../@types";
import { GAME_TO_ICON_MAP } from "../../Components/GameSelector";
import { CampaignWidget } from "../Competitions/Components/Widget";

const Homepage = () => {
  const { contentFeeds, contentFeedsLoading } = HomepageViewModel();

  return (
    <HomepageWrapper>
      <HomepageContainer>
        <Featured />
        <CampaignWidget />
        <ContentFeed
          title="For You"
          data={contentFeeds?.forYou?.nodes || contentFeeds?.forYou}
          loading={contentFeedsLoading}
        />
        <ContentFeed
          title="Counter-Strike 2"
          data={contentFeeds?.cs2?.data}
          loading={contentFeedsLoading}
          icon={GAME_TO_ICON_MAP[ESupportedGames.CS2]}
        />
        <SuggestedFeed />
        <ContentFeed
          title="Popular Montages"
          data={contentFeeds?.montages?.data}
          loading={contentFeedsLoading}
          isMontageFeed={true}
        />
        <ContentFeed
          title="League of Legends"
          data={contentFeeds?.league?.data}
          loading={contentFeedsLoading}
          icon={GAME_TO_ICON_MAP[ESupportedGames.LOL]}
        />
        <ContentFeed
          title="Fortnite"
          data={contentFeeds?.fortnite?.data}
          loading={contentFeedsLoading}
          icon={GAME_TO_ICON_MAP[ESupportedGames.FORTNITE]}
        />
        <ContentFeed
          title="Dota 2"
          data={contentFeeds?.dota2?.data}
          loading={contentFeedsLoading}
          icon={GAME_TO_ICON_MAP[ESupportedGames.DOTA2]}
        />
      </HomepageContainer>
    </HomepageWrapper>
  );
};

const HomepageContainer = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  max-width: 2480px;
  padding-bottom: 80px;
  width: 100vw;
`;

export default Homepage;
