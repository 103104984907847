import { Flex, Text } from "rebass/styled-components";
import styled from "styled-components";

import { Tooltip } from "../../../../Components/Tooltip";
import { Avatar } from "../../../../Components/Avatar";

interface IFollowedByFollowingProps {
  followedBy: [
    {
      username: string;
      avatar: string;
    },
  ];
}

const FollowedByFollowing = ({ followedBy }: IFollowedByFollowingProps) => (
  <StyledTooltip
    tooltipContent={<FollowedByFollowingTooltip followedBy={followedBy} />}
  >
    <Flex alignItems="center" style={{ gap: "8px" }}>
      <FollowersList>
        {followedBy.slice(0, 4).map((follower) => (
          <Avatar image={follower.avatar} size="tiny" />
        ))}
      </FollowersList>
      <Text fontSize="1">Following</Text>
    </Flex>
  </StyledTooltip>
);

const FollowedByFollowingTooltip = ({
  followedBy,
}: IFollowedByFollowingProps) => (
  <Flex flexDirection="column">
    {followedBy.map((follower) => (
      <Text fontSize="1">{follower.username}</Text>
    ))}
  </Flex>
);

const FollowersList = styled(Flex)`
  > * {
    border: 1px solid ${({ theme }) => theme.colors.gray850};
    margin-left: -10px;
  }
`;

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

export { FollowedByFollowing };
