import React from "react";
import Legal from "./index";
import { Body } from "../../components/text";
import styled from "styled-components";

const CommunityTrustAndSafetyGuidelines = () => {
  return (
    <Legal>
      <StyledBody>
        <p>Last updated on December 17th, 2024</p>
        <div style={{ paddingBottom: 100 }}>
          <h2 data-testid="legal/community-trust-and-safety-guidelines">
            Allstar Community Trust & Safety Guidelines
          </h2>

          <p>
            Welcome to Allstar! We’re committed to creating a safe, respectful,
            and enjoyable environment for all users, which is why we’ve created
            these community guidelines. As a U.S.-based company, we follow all
            applicable U.S. laws, and we expect our users to do the same. By
            using Allstar, you agree to these guidelines and understand that we
            may take action if content violates our standards.
          </p>

          <h3>General Content Standards</h3>
          <p>
            Our community guidelines are intended to foster a creative and
            inclusive environment. To that end, please adhere to the following
            content standards:
          </p>

          <ul>
            <li>
              <strong>Respect for Others:</strong> Content should reflect
              respect toward all individuals, groups, and communities. Do not
              target, harass, or demean others or promote hate in any content
              you create while using Allstar.
            </li>
            <li>
              <strong>Legal and Safe Content:</strong> Content must comply with
              U.S. laws and should not promote, endorse, or depict dangerous,
              illegal, or harmful acts. This includes audio content that could
              potentially incite violence, promote terrorism, or involve child
              exploitation.
            </li>
            <li>
              <strong>Authentic and Transparent:</strong> Content should be
              genuine to your gameplay. Avoid using deceptive practices,
              hijacked accounts or impersonating others.
            </li>
            <li>
              <strong>Inclusive Content:</strong> Content should not
              discriminate or promote negative stereotypes based on race,
              religion, gender, nationality, or other personal identities.
            </li>
          </ul>

          <h3>Compliance with U.S. Laws</h3>
          <p>
            As a U.S.-based platform, Allstar complies with relevant U.S. laws.
            By using our platform, users agree to follow these laws, including
            but not limited to:
          </p>

          <ul>
            <li>
              <strong>
                Section 230 of the Communications Decency Act (CDA):
              </strong>{" "}
              This law allows us to moderate content while protecting us from
              liability for most user-generated content. We reserve the right to
              remove or moderate content we consider harmful, inappropriate, or
              in violation of these guidelines.
            </li>
            <li>
              <strong>Digital Millennium Copyright Act (DMCA):</strong> We
              respect intellectual property rights and comply with the DMCA.
              Users should avoid posting content that infringes on copyright or
              intellectual property. We may remove or restrict content in
              response to valid copyright claims and terminate repeat infringers
              as necessary.
            </li>
            <li>
              <strong>Children's Online Privacy Protection Act (COPPA):</strong>{" "}
              Allstar is not intended for users under 13 years of age.
            </li>
            <li>
              <strong>Anti-Terrorism and Exploitation Laws:</strong> Content
              that promotes or endorses terrorism, child exploitation, or abuse
              of any kind is strictly prohibited and may be reported to law
              enforcement.
            </li>
          </ul>

          <h3>Platform Rights and Enforcement</h3>
          <p>
            We reserve the right to moderate and manage content as needed to
            protect our community and comply with the law. Here's how we may act
            to enforce these guidelines:
          </p>

          <ul>
            <li>
              <strong>Content Review and Removal:</strong> We may review,
              remove, or limit access to content that violates these guidelines
              or relevant laws. This may happen with or without notice, at our
              discretion.
            </li>
            <li>
              <strong>Account Suspension or Termination:</strong> If users
              repeatedly or seriously violate these guidelines or our Terms of
              Service, we may suspend or terminate their accounts.
            </li>
            <li>
              <strong>Moderation Tools and Processes:</strong> We may use
              automated tools, human moderators, or other means to manage
              content, helping to keep our platform safe and in compliance with
              U.S. laws.
            </li>
            <li>
              <strong>Reporting to Authorities:</strong> When content presents
              serious risks or legal concerns, such as violations of child
              safety or anti-terrorism laws, we may report it to the appropriate
              authorities.
            </li>
          </ul>

          <h3>Remedies and Right to Appeal</h3>
          <p>
            Enforcement actions are within our sole discretion, and users agree
            to hold us harmless from consequences arising from these actions.
            Remedies and appeal options include:
          </p>

          <ul>
            <li>
              <strong>Content Restrictions:</strong> We may limit users' ability
              to post or interact on the platform if they violate our standards
              or applicable U.S. laws.
            </li>
            <li>
              <strong>Warnings and Escalated Actions:</strong> Users may receive
              a warning for guideline violations. Repeated or serious violations
              may lead to stronger actions, including suspension or termination.
            </li>
            <li>
              <strong>Requesting Review:</strong> Users can request a review of
              enforcement actions if they believe an error was made. While we
              will review requests, restoration of content or account access is
              not guaranteed. To appeal any actionable measure Allstar has taken
              against your account please email our support team at
              support@allstar.gg
            </li>
          </ul>

          <h3>Policy Changes and Updates</h3>
          <p>
            We may update these guidelines as necessary to reflect changes in
            the law or our community standards. Major changes will be
            communicated, and continued use of Allstar signifies acceptance of
            the latest version.
          </p>

          <h3>Final Reminder</h3>
          <p>
            We strive to create a community that is safe, positive, and
            inclusive for everyone. By following these guidelines, you're
            helping us achieve that goal. Thank you for being a part of Allstar
            - GLHF!
          </p>

          <h3>Additional Materials</h3>
          <ul>
            <li>
              <a
                href="https://crsreports.congress.gov/product/pdf/R/R46751"
                target="_blank"
                rel="noopener noreferrer"
              >
                Congressional Research Service Report on Section 230
              </a>
            </li>
            <li>
              <a
                href="https://www.copyright.gov/dmca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Digital Millennium Copyright Act (DMCA)
              </a>
            </li>
            <li>
              <a
                href="https://www.ftc.gov/legal-library/browse/rules/childrens-online-privacy-protection-rule-coppa"
                target="_blank"
                rel="noopener noreferrer"
              >
                Children's Online Privacy Protection Rule (COPPA)
              </a>
            </li>
          </ul>
        </div>
      </StyledBody>
    </Legal>
  );
};
const StyledBody = styled(Body)`
  line-height: 1.444;
  font-size: 1.125rem;
`;
export default CommunityTrustAndSafetyGuidelines;
